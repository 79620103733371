import { GetTableDataDto } from "@/shared/globals/tables/dtos/GetTableData.dto";
import { REQ_RES_CODES } from "@/shared/http/enums/request-response-codes.enum";
import { UserInterface } from "@/store/auth/interfaces/User.interface";
import { User } from "@/store/auth/models/User.model";
import { CreateOrUpdateUserInterface } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import {
  CreateUser,
  DeleteUser,
  DisableUser,
  EnableUser,
  GetUsers,
  UpdateUser,
} from "@/store/users/services/Users.service";
import { computed, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastService";

export function useUsersServices() {
  const store = useStore();
  const { t } = useI18n();
  const user = computed(() => {
    return store.state.auth.user;
  });

  const currentUsers: Ref<User[]> = ref([]);

  function getUsers(tablePayload: GetTableDataDto) {
    GetUsers(tablePayload)
      .then(({ data }) => {
        currentUsers.value = data;
      })
      .catch(() => {
        useToast().errorToast(t("global.unknownError"));
      });
  }

  async function createUser(
    userPayload: CreateOrUpdateUserInterface
  ): Promise<UserInterface | void> {
    return await CreateUser(userPayload)
      .then((user) => {
        useToast().successToast(t("users.sucess.userCreated"));
        return user;
      })
      .catch(({ response }) => {
        if (response.messageCode === REQ_RES_CODES.ENTITY_ALREADY_EXIST) {
          useToast().errorToast(t("users.errors.userAlreadyExists"));
        } else {
          useToast().errorToast(t("global.unknownError"));
        }
      });
  }

  async function updateUser(
    userPayload: CreateOrUpdateUserInterface
  ): Promise<UserInterface | void> {
    return await UpdateUser(userPayload)
      .then((user) => {
        useToast().successToast(t("users.sucess.userUpdated"));
        return user;
      })
      .catch(() => {
        useToast().errorToast(t("global.unknownError"));
        return;
      });
  }

  async function enableUser(
    userPayload: CreateOrUpdateUserInterface
  ): Promise<UserInterface | void> {
    return await EnableUser(userPayload.id)
      .then((user) => {
        useToast().successToast(t("users.sucess.statusUpdated"));
        return user;
      })
      .catch(() => {
        useToast().errorToast(t("global.unknownError"));
        return;
      });
  }

  async function disableUser(
    userPayload: CreateOrUpdateUserInterface
  ): Promise<UserInterface | void> {
    return await DisableUser(userPayload.id)
      .then((user) => {
        useToast().successToast(t("users.sucess.statusUpdated"));
        return user;
      })
      .catch(() => {
        useToast().errorToast(t("global.unknownError"));
        return;
      });
  }

  async function deleteUser(
    userPayload: CreateOrUpdateUserInterface
  ): Promise<boolean> {
    return await DeleteUser(userPayload.id)
      .then(() => {
        useToast().successToast(t("users.sucess.userDeleted"));
        return true;
      })
      .catch(() => {
        useToast().errorToast(t("global.unknownError"));
        return false;
      });
  }

  return {
    user,
    getUsers,
    currentUsers,
    createUser,
    updateUser,
    enableUser,
    disableUser,
    deleteUser,
  };
}
