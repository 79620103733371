<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formUserTemplateRef"
          form-name="usersForm"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useProfilesServices } from "@/composables/useProfilesServices";
import { useUsersServices } from "@/composables/useUsersServices";
import { UserInterface } from "@/store/auth/interfaces/User.interface";
import {
  CreateOrUpdateUserInterface,
  CustomFieldValues,
} from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import {
  defineEmits,
  defineExpose,
  defineProps,
  nextTick,
  onMounted,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { CreateOrUpdateUserTemplate } from "./forms/templates/CreateOrUpdateUser.template";

const { customFields } = useCustomFieldsServices();
const { profiles, getAllProfiles } = useProfilesServices();
const { createUser, updateUser } = useUsersServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();

const props = defineProps({
  initialValues: { type: Object as PropType<UserInterface>, required: false },
});

const initialValuesRef: Ref<UserInterface> = toRef(props, "initialValues");
const form = ref();
const formUserTemplateRef = ref(
  CreateOrUpdateUserTemplate(
    customFields.value,
    profiles.value,
    catalogs.value,
    handleSubmit,
    !initialValuesRef.value
  )
);

const emit = defineEmits(["handleSubmit"]);

watch([profiles, initialValuesRef, catalogs], () => {
  formUserTemplateRef.value = CreateOrUpdateUserTemplate(
    customFields.value,
    profiles.value,
    catalogs.value,
    handleSubmit,
    !initialValuesRef.value
  );
  if (!initialValuesRef.value) {
    resetForms();
  }
});

async function handleSubmit() {
  const { user } = form.value.values;
  if (!initialValuesRef.value) {
    const newUser = await createUser(user);
    if (newUser) {
      emit("handleSubmit", { user: newUser, isCreating: true });
    }
  } else {
    const editedUser = { ...user, ...(await updateUser(user)) };
    if (editedUser) {
      emit("handleSubmit", { user: editedUser, isCreating: false });
    }
  }
}

function formatValues(values: UserInterface): CreateOrUpdateUserInterface {
  // Limpiar los custom fields ya sea de los custom fields borrados u options de un custom fields borrados
  const customFieldsFiltered: CustomFieldValues = Object.fromEntries(
    Object.entries(values.customFields)
      .filter(([id]) => {
        return (
          customFields.value.findIndex((customField) => customField.id === id) >
          -1
        );
      })
      .map((customField) => {
        const [id, values] = customField;

        // Es una opcion del multi-select
        if (Array.isArray(values)) {
          const newValues = values.filter(({ value }) => {
            const customField = customFields.value.find(
              (customField) => customField.id === id
            );
            return (
              Object.keys(customField.metadata.options).findIndex((key) => {
                return key === value;
              }) > -1
            );
          });
          return [id, newValues];
        }
        return [id, values];
      })
  );
  const user: CreateOrUpdateUserInterface = {
    id: values.id,
    name: values.name,
    email: values.email,
    profile: { id: `${values.profile.id}` },
    status: { id: `${values.status.id}` },
    customFields: customFieldsFiltered ?? {},
  };
  return user;
}

onMounted(async () => {
  await getAllProfiles();
  await getAllCatalogs();
});

watch(
  initialValuesRef,
  async () => {
    await nextTick();
    resetForms();
    if (initialValuesRef.value) {
      const user = formatValues(initialValuesRef.value);
      form.value?.setValues({ user });
    }
  },
  { immediate: true, deep: true }
);

function resetForms() {
  form.value.resetForm();
  formUserTemplateRef.value.sections.forEach((section) => {
    section.fields.forEach((field) => {
      if (field.type === "select") {
        form.value?.setFieldValue(field.path, "");
      }
    });
  });
}

defineExpose({
  resetForms,
});
</script>

<style lang="scss"></style>
