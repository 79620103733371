import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  isEmailYup,
  minCharsYup,
  passwordValidation,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { CatalogClassesKeys } from "@/store/catalogs/const/CatalogClasses.const";
import { CatalogClassInterface } from "@/store/catalogs/interfaces/CatalogClass.interface";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";
import { Profile } from "@/store/profiles/models/Profile.model";

export const CreateOrUpdateUserTemplate = (
  customFields: CustomFieldInterface[],
  profiles: Profile[],
  catalogs: CatalogClassInterface[],
  action,
  isCreating: boolean
): Form => {
  const catalog = catalogs.find((catalog) => {
    return catalog.key === CatalogClassesKeys.USERS_STATUS_CLASS;
  });
  const { lang } = useLanguageServices();
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "user.name",
            inputName: "userName",
            label: {
              value: "users.form.userName",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, minCharsYup(8)];
            },
          },
          {
            path: "user.email",
            inputName: "userEmail",
            label: {
              value: "users.form.email",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, isEmailYup];
            },
          },
          {
            conditions() {
              return isCreating;
            },
            path: "user.password",
            inputName: "userPassword",
            label: {
              value: "users.form.password",
              needsTranslate: true,
            },
            type: "password",
            methodsYup: () => {
              return passwordValidation;
            },
          },
          {
            path: "user.profile.id",
            inputName: "userProfile",
            label: {
              value: "users.form.profile",
              needsTranslate: true,
            },
            type: "select",
            options: [
              ...profiles.map((profile) => {
                return {
                  name: { value: profile.name, needsTranslate: false },
                  value: profile.id,
                };
              }),
            ],
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
          {
            path: "user.status.id",
            inputName: "userStatus",
            label: {
              value: "users.form.initialStatus",
              needsTranslate: true,
            },
            type: "select",
            options: [
              ...(catalog?.catalogs.map((catalog) => {
                return {
                  name: {
                    value: catalog?.catalogMetadata?.language
                      ? catalog?.catalogMetadata?.language[lang.value]
                      : catalog.name,
                    needsTranslate: false,
                  },
                  value: catalog.id,
                };
              }) ?? []),
            ],
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating ? "users.form.addUser" : "users.form.updateUser",
        needsTranslate: true,
      },
      action,
    },
  };

  if (customFields.length > 0) {
    form.sections.push({
      name: { value: "customFields.title", needsTranslate: true },
      fields: customFieldsToField(customFields, "user", "user"),
    });
  }

  return form;
};
