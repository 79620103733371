import { Profile } from "@/store/profiles/models/Profile.model";
import {
  CreateProfile,
  DeleteProfile,
  GetAllProfiles,
  GetProfiles,
  UpdateProfile,
} from "@/store/profiles/services/Profiles.service";
import { computed, ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastService";

export function useProfilesServices() {
  const store = useStore();
  const { t } = useI18n();

  const profiles = computed(() => {
    return store.state.profiles.profiles;
  });

  const profileList: Ref<Profile[]> = ref([]);

  const getAllProfiles = async (): Promise<void> => {
    if (store.state.profiles.profiles.length === 0) {
      GetAllProfiles()
        .then((profiles: Partial<Profile>[]) => {
          store.commit("profiles/setProfiles", profiles);
        })
        .catch(({ response }) => {
          return;
        });
    }
  };

  const getProfiles = async (): Promise<void> => {
    GetProfiles()
      .then((profilesResponse: Profile[]) => {
        profileList.value = profilesResponse;
      })
      .catch(({ response }) => {
        return;
      });
  };

  const createProfile = async (payload: Profile): Promise<Profile | void> => {
    return CreateProfile(payload)
      .then((profile: Profile) => {
        store.commit("profiles/addProfile", {
          id: profile.id,
          name: profile.name,
          metadata: profile.metadata,
        });
        return profile;
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
        return;
      });
  };

  const updateProfile = async (payload: Profile): Promise<Profile | void> => {
    return UpdateProfile(payload)
      .then((profile: Profile) => {
        store.commit("profiles/updateProfile", {
          id: profile.id,
          name: profile.name,
          metadata: profile.metadata,
        });
        return profile;
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
        return;
      });
  };

  const deleteProfile = async (payload: Profile): Promise<boolean> => {
    return DeleteProfile(payload.id)
      .then(() => true)
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
        return false;
      });
  };

  return {
    profiles,
    getAllProfiles,
    createProfile,
    updateProfile,
    deleteProfile,
    getProfiles,
    profileList,
  };
}
