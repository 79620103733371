<template>
  <GeneralCard>
    <template v-slot:content>
      <div class="container-fluid mx-3 mt-2">
        <div class="row mt-3">
          <div class="col text-start">
            <h5 class="title">{{ $t("home.dashboard.lastTransactions") }}</h5>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <div
              v-for="transaction of transactions"
              :key="transaction.name"
              class="row mt-3"
            >
              <div class="col">
                <TransactionItem
                  :name="transaction.name"
                  :date="new Date()"
                ></TransactionItem>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </GeneralCard>
</template>

<script setup lang="ts">
import GeneralCard from "@/components/globals/cards/GeneralCard.vue";
import TransactionItem from "@/components/dashboard/TransactionItem.vue";

const transactions = [
  { name: "Nuevo cliente: [CLIENTE 01]", date: new Date() },
  { name: "Mail sent to HR and Admin", date: new Date() },
  { name: "Server Logs Updated", date: new Date() },
  { name: "Task Completed : [Backup Files EOD]", date: new Date() },
  { name: "Documents Submitted from Sara", date: new Date() },
];
</script>

<style lang="scss">
.title {
  font-size: 18px;
  font-weight: 500;
  color: #0e1726;
}
</style>
