<template>
  <div class="row">
    <div class="col-12">
      <h1 style="display: flex">{{ $t("rawMaterials.title") }}</h1>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-start">
              <button
                type="button"
                class="btn btn-primary"
                @click="() => openOffcanvasForm('createMeasures')"
              >
                <i class="bi bi-person-plus me-2"></i>
                {{ $t("rawMaterials.form.title") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-end">
              <button
                type="button"
                class="btn ml-1 btn-gear"
                @click="() => openOffcanvasForm('createCustomField')"
              >
                <i class="bi bi-gear"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GeneralOffcanvas
    canvasId="customFieldCanvas"
    ref="offCanvasRef"
    :canvasName="
      formName === 'createCustomField'
        ? $t('customFields.title')
        : $t('users.form.title')
    "
    @closed="customFieldsClosed"
    ><template v-slot:content>
      <CustomFields
        v-if="formName === 'createCustomField'"
        ref="form"
        @custom-field-submitted="handleCustomFieldSubmit"
      ></CustomFields>
      <CreateOrUpdateUser
        v-if="formName === 'createMeasures' || formName === 'editMeasures'"
        ref="form"
        :initial-values="userTemp"
        @handle-submit="handleUserSubmit"
      ></CreateOrUpdateUser>
    </template>
  </GeneralOffcanvas>
  <ConfirmModal
    :title="confirmModalTitleTranslate"
    ref="confirmationModalRef"
    :message="confirmationQuestion"
    :confirmAction="handleConfirm"
  ></ConfirmModal>
  <GeneralTable
    v-if="usersHeaderTable"
    dataSource="/raw-materials/"
    :filters="tableFilters"
    :headers="usersHeaderTable"
    name="usersTable"
    :label="$t('global.tables.users.label')"
    ref="userTable"
  ></GeneralTable>
</template>

<script lang="ts" setup>
import CustomFields from "@/components/customFields/CustomFields.vue";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import CreateOrUpdateUser from "@/components/users/CreateOrUpdateUser.vue";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useUsersServices } from "@/composables/useUsersServices";
import { User } from "@/store/auth/models/User.model";
import { Offcanvas } from "bootstrap";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { RawMaterialsHeader } from "./headers/RawMaterialsHeader.headers";

const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { enableUser, disableUser, deleteUser } = useUsersServices();
type formsName = "createMeasures" | "editMeasures" | "createCustomField";

const form = ref();
const userTable = ref();
const offCanvasRef = ref();
const usersHeaderTable = ref();
const formName: Ref<formsName> = ref();
const userTemp: Ref<User | null> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<"edit" | "disable" | "enable" | "delete"> = ref();
const confirmModalTitleTranslate: Ref<string> = ref("");
const { t } = useI18n();
onMounted(async () => {
  await getModuleCustomFields();
  watch(
    customFields,
    () => {
      usersHeaderTable.value = RawMaterialsHeader(
        optionEdit,
        optionDelete,
        customFields.value
      );
    },
    { immediate: true, deep: true }
  );
});

const customFieldsClosed = () => {
  return form.value.resetForms && form.value.resetForms();
};

const tableFilters = ref([]);

function handleUserSubmit(data) {
  const { user, isCreating } = data;
  if (isCreating) {
    userTable.value.currentData = [user, ...userTable.value.currentData];
  } else {
    userTable.value.currentData[lastRowIndex.value] = user;
  }
  closeOffcanvasForm();
}

function handleCustomFieldSubmit() {
  closeOffcanvasForm();
}

const confirmationQuestion = computed(() => {
  return {
    value: `users.${question.value}`,
    params: {
      name: userTemp.value?.name || "",
    },
    needsTranslate: true,
  };
});

const question = computed(() => {
  if (action.value === "disable") {
    return "questionDisable";
  } else if (action.value === "enable") {
    return "questionEnable";
  } else if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

function optionEdit(user: User, index: number) {
  openOffcanvasForm("editMeasures");
  action.value = "edit";
  userTemp.value = { ...user };
  lastRowIndex.value = index;
}

function optionDisable(user: User, index: number) {
  action.value = "disable";
  confirmModalTitleTranslate.value = t("global.warnings.status");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionEnable(user: User, index: number) {
  action.value = "enable";
  confirmModalTitleTranslate.value = t("users.warnings.status");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionDelete(user: User, index: number) {
  action.value = "delete";
  confirmModalTitleTranslate.value = t("global.warningDelete");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

async function handleConfirm() {
  let user = null;
  if (["enable", "disable"].includes(action.value)) {
    if (action.value === "enable") {
      user = await enableUser(userTemp.value);
    } else {
      user = await disableUser(userTemp.value);
    }
    userTable.value.currentData[lastRowIndex.value] = {
      ...userTemp.value,
      status: { id: user.status.id },
    };
  } else if (action.value === "delete") {
    const userDeleted = await deleteUser(userTemp.value);
    if (userDeleted) {
      userTable.value.currentData = userTable.value.currentData.filter(
        (user: User, index) => {
          return index !== lastRowIndex.value;
        }
      );
    }
  }
}

function openOffcanvasForm(newFormName: formsName) {
  userTemp.value = null;
  formName.value = newFormName;
  const offcanvasInstance = Offcanvas.getOrCreateInstance(
    offCanvasRef.value.$el
  );
  offcanvasInstance.show();
}

function closeOffcanvasForm() {
  const offcanvasInstance = Offcanvas.getOrCreateInstance(
    offCanvasRef.value.$el
  );
  offcanvasInstance.hide();
}
</script>

<style scoped>
.btn-gear {
  color: white;
  background-color: #7451c2;
  border-color: #7451c2;
}
</style>
