import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { style: {"display":"flex"} }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "row g-2" }
const _hoisted_8 = { class: "col-12 d-flex justify-content-start" }

import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import CreateOrUpdateProfile from "@/components/profiles/CreateOrUpdateProfile.vue";
import { usePermissionsServices } from "@/composables/usePermissionsServices";
import { useProfilesServices } from "@/composables/useProfilesServices";
import { User } from "@/store/auth/models/User.model";
import { Profile } from "@/store/profiles/models/Profile.model";
import { Offcanvas } from "bootstrap";
import { computed, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { ProfileHeader } from "./headers/ProfileHeader.headers";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfilesView',
  setup(__props) {

const { permissions, getPermissions } = usePermissionsServices();
const { deleteProfile } = useProfilesServices();
const form = ref();
const profileTable = ref();
const offCanvasRef = ref();
const profilesHeaderTable = ref();
const profileTemp: Ref<Profile | null> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<"edit" | "delete"> = ref();
const confirmModalTitleTranslate: Ref<string> = ref("");
const { t } = useI18n();

watch(
  permissions,
  async () => {
    await getPermissions();
    profilesHeaderTable.value = ProfileHeader(
      optionEdit,
      optionDelete,
      permissions.value
    );
  },
  { deep: true, immediate: true }
);

const customFieldsClosed = () => {
  return form.value.resetForms && form.value.resetForms();
};

const tableFilters = ref([]);

function handleProfileSubmit(data) {
  const { profile, isCreating } = data;
  if (isCreating) {
    profileTable.value.currentData = [
      profile,
      ...profileTable.value.currentData,
    ];
  } else {
    profileTable.value.currentData[lastRowIndex.value] = {
      ...profileTable.value.currentData[lastRowIndex.value],
      ...profile,
    };
  }
  closeOffcanvasForm();
}

const confirmationQuestion = computed(() => {
  return {
    value: `profiles.${question.value}`,
    params: {
      name: profileTemp.value?.name || "",
    },
    needsTranslate: true,
  };
});

const question = computed(() => {
  if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

function optionEdit(profile: Profile, index: number) {
  openOffcanvasForm();
  action.value = "edit";
  profileTemp.value = { ...profile };
  lastRowIndex.value = index;
}

function optionDelete(profile: Profile, index: number) {
  action.value = "delete";
  confirmModalTitleTranslate.value = t("global.warningDelete");
  profileTemp.value = { ...profile };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

async function handleConfirm() {
  let profile = null;
  if (action.value === "delete") {
    const userDeleted = await deleteProfile(profileTemp.value);
    if (userDeleted) {
      profileTable.value.currentData = profileTable.value.currentData.filter(
        (profile: User, index) => {
          return index !== lastRowIndex.value;
        }
      );
    }
  }
}

function openOffcanvasForm() {
  profileTemp.value = null;
  const offcanvasInstance = Offcanvas.getOrCreateInstance(
    offCanvasRef.value.$el
  );
  offcanvasInstance.show();
}

function closeOffcanvasForm() {
  const offcanvasInstance = Offcanvas.getOrCreateInstance(
    offCanvasRef.value.$el
  );
  offcanvasInstance.hide();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("profiles.title")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[0] || (_cache[0] = () => openOffcanvasForm())
                }, [
                  _cache[1] || (_cache[1] = _createElementVNode("i", { class: "bi bi-person-plus me-2" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("profiles.add")), 1)
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(GeneralOffcanvas, {
      canvasId: "customFieldCanvas",
      ref_key: "offCanvasRef",
      ref: offCanvasRef,
      canvasName: _ctx.$t('profiles.form.title'),
      onClosed: customFieldsClosed
    }, {
      content: _withCtx(() => [
        _createVNode(CreateOrUpdateProfile, {
          ref_key: "form",
          ref: form,
          "initial-values": profileTemp.value,
          onHandleSubmit: handleProfileSubmit
        }, null, 8, ["initial-values"])
      ]),
      _: 1
    }, 8, ["canvasName"]),
    _createVNode(ConfirmModal, {
      title: confirmModalTitleTranslate.value,
      ref_key: "confirmationModalRef",
      ref: confirmationModalRef,
      message: confirmationQuestion.value,
      confirmAction: handleConfirm
    }, null, 8, ["title", "message"]),
    (profilesHeaderTable.value)
      ? (_openBlock(), _createBlock(GeneralTable, {
          key: 0,
          dataSource: "/profiles/",
          filters: tableFilters.value,
          headers: profilesHeaderTable.value,
          name: "usersTable",
          label: _ctx.$t('global.tables.users.label'),
          ref_key: "profileTable",
          ref: profileTable
        }, null, 8, ["filters", "headers", "label"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})