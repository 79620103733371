import { Module } from "vuex";
import { Profile } from "./models/Profile.model";

interface State {
  profiles?: Partial<Profile>[];
}

export const profilesStore: Module<State, unknown> = {
  namespaced: true,
  state: {
    profiles: [],
  },
  getters: {},
  mutations: {
    setProfiles(state, profiles: Partial<Profile>[]) {
      state.profiles = profiles;
    },
    addProfile(state, profile: Partial<Profile>) {
      state.profiles = [...state.profiles, profile];
    },
    updateProfile(state, profile: Partial<Profile>) {
      state.profiles = state.profiles.filter((p) => p.id !== profile.id);
      state.profiles = [...state.profiles, profile];
    },
  },
  actions: {},
  modules: {},
};
