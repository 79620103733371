<template>
  <AuthCard>
    <template v-slot:content>
      <div class="row">
        <div class="col-12 mb-2">
          <h2 class="fs-2">
            {{ $t("login.access_title") }}
          </h2>
          <p class="title">
            {{ $t("login.title") }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <GeneralForm ref="formRef" :form="formTemplate" formName="loginUser">
            <div class="row g-2 justify-content-between">
              <div class="col-auto">
                <span
                  class="actionableLabel"
                  @click="goToForgotPassword"
                  data-bs-target="#carouselAuthViews"
                  data-bs-slide-to="1"
                  >{{ $t("login.forgotPassword") }}</span
                >
              </div>
              <div class="col-auto">
                <span class="actionableLabel" @click="goToValidateEmail">{{
                  $t("login.validateEmail")
                }}</span>
              </div>
            </div>
          </GeneralForm>
        </div>
      </div>
      <div class="row" id="anotherOptionsDivider">
        <div class="col pr-1"><hr /></div>
        <div class="col-auto p-0 dividerText d-flex align-items-center">
          <p class="m-0 fs-6">{{ $t("login.anotherAccessOptions") }}</p>
        </div>
        <div class="col pl-1"><hr /></div>
      </div>
      <div class="row justify-content-around pt-2 pb-3" id="anotherOption">
        <div class="col">
          <button class="google-button btn extraOptionButton p-0 w-100 p-2">
            <img
              class="bg-transparent"
              src="images/icon/brands/icon-google.svg"
              width="30px"
              alt="googleLogo"
            />
          </button>
        </div>
      </div>
    </template>
  </AuthCard>
</template>

<script lang="ts" setup>
import { useAuthServices } from "@/composables/useAuthServices";
import { useValidations } from "@/composables/useValidations";
import { defineEmits, ref } from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import AuthCard from "./AuthCard.vue";
import { LoginUserForm } from "./forms/templates/loginUser.template";

const validations = useValidations();
const { login } = useAuthServices();

const emit = defineEmits(["goToSlide"]);

const formRef = ref();
const formTemplate = ref(LoginUserForm(submitLogin, validations));

const initialValues = { email: "", password: "" };

async function submitLogin(): Promise<void> {
  const { values } = formRef.value;
  await login(values, goToForgotPassword);
}

function goToForgotPassword(): void {
  formRef.value.resetForm();
  emit("goToSlide", 0);
}
function goToValidateEmail(): void {
  formRef.value.resetForm({ values: initialValues });
  emit("goToSlide", 2);
}
</script>

<style lang="scss" scoped>
.createAccount {
  font-size: 14px;
}

.title {
  font-size: 15px;
}

.google-button {
  background-color: #dae2e9;
}

.link {
  color: #dbc7a2;
}

.input-group {
  $labelWith: 35%;
  $inputWith: calc(100% - $labelWith);
  span {
    width: $labelWith;
    text-align: start;
  }
  input {
    width: $inputWith;
  }
}

#anotherOptionsDivider {
  hr {
    color: rgb(71, 71, 71);
  }
  font-size: 0.6em;
  color: rgb(0, 0, 0);
}

#anotherOption {
  button {
    &:hover {
      background-color: $FourthColor;
    }
  }
}

.extraOptionButton {
  &:hover {
    background-color: rgb(206, 206, 206) !important;
  }
}
</style>
