import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, unref as _unref } from "vue"

const _hoisted_1 = { class: "container text-start" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = {
  key: 0,
  class: "row mt-3",
  id: "anotherOptionsDivider"
}
const _hoisted_4 = { class: "col-auto p-0 dividerText d-flex align-items-center" }
const _hoisted_5 = { class: "m-0 fs-5" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = {
  key: 0,
  class: "col-12 mt-2"
}
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = { class: "col-12 p-0 mt-2" }

import InputMaster from "@/components/globals/inputs/InputMaster.vue";
import { Form as FormType } from "@/shared/globals/forms/interfaces/Form.interface";
import { YupValidator } from "@/shared/globals/helpers/YupValidator";
import { useForm } from "vee-validate";
import {
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import ButtonMaster from "../buttons/ButtonMaster.vue";
import ProcessText from "../UiTools/ProcessText.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralForm',
  props: {
  form: { type: Object as PropType<FormType>, required: true },
  formName: { type: String, required: true },
},
  setup(__props, { expose: __expose }) {

const props = __props;

const yupValidator = ref(createYupValidator());

const formProps = useForm();
const { values, handleSubmit, isSubmitting, setErrors } = formProps;
const errorBag = ref();

const onSubmit = handleSubmit(async () => {
  const isValid = await validateForm();
  if (isValid) {
    await props.form.submit.action();
  }
});

async function validateForm(): Promise<boolean> {
  const validations = await yupValidator.value.getValidations(values);
  const isValid = Object.values(validations).every((validation) => {
    const value = validation as { error: string; valid: boolean }[];
    return value.every((v) => v.valid);
  });
  if (!isValid) {
    setErrors(validations);
  }
  return isValid;
}

function createYupValidator() {
  const yupValidator = new YupValidator();
  const schema = props.form
    ? props.form.sections.reduce((acc, section) => {
        if (!section.conditions || section.conditions()) {
          section.fields.reduce((acc, field) => {
            if ((!field.conditions || field.conditions()) && field.methodsYup) {
              const keys = field.path.split(".");
              keys.reduce((acc, key, index, arr) => {
                if (index === arr.length - 1) {
                  acc[key] = field.methodsYup();
                }
                return acc[key] || (acc[key] = {});
              }, acc);
            }
            return acc;
          }, acc);
        }

        return acc;
      }, {})
    : {};
  yupValidator.addSchema(schema, []);
  return yupValidator;
}

watch(
  values,
  () => {
    yupValidator.value = createYupValidator();
  },
  { deep: true }
);

watch(values, async () => {
  const error = await yupValidator.value.getValidations(values);
  errorBag.value = error;
});

onMounted(async () => {
  const error = await yupValidator.value.getValidations(values);
  errorBag.value = error;
});

__expose({ ...formProps, validateForm });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("form", {
      onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args))),
      class: "row",
      key: __props.formName
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.form?.sections, (section, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-12",
          key: `${__props.formName}Section-${index}`
        }, [
          (section.conditions ? section.conditions() : true)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (section.name)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-1" }, [
                        _createElementVNode("hr")
                      ], -1)),
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("b", _hoisted_5, [
                          _createVNode(ProcessText, {
                            value: section.name
                          }, null, 8, ["value"])
                        ])
                      ]),
                      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col pl-1" }, [
                        _createElementVNode("hr")
                      ], -1))
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section?.fields, (field, fieldIndex) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "co-12",
                    key: `${__props.formName}Section-${index}-Field-${fieldIndex}`
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      (field.conditions ? field.conditions() : true)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(InputMaster, {
                              inputName: field.inputName,
                              path: field.path,
                              label: field.label,
                              type: field.type,
                              icon: field.icon,
                              placeholder: field.placeholder,
                              options: field.options,
                              buttons: field.buttons,
                              metadata: field.metadata,
                              errorBag: errorBag.value,
                              disabled: field.disabled
                            }, null, 8, ["inputName", "path", "label", "type", "icon", "placeholder", "options", "buttons", "metadata", "errorBag", "disabled"])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(ButtonMaster, {
                text: __props.form?.submit?.text,
                isLoading: _unref(isSubmitting)
              }, null, 8, ["text", "isLoading"])
            ])
          ])
        ])
      ])
    ], 32))
  ]))
}
}

})