import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-center" }
const _hoisted_2 = { class: "col-12 d-flex align-items-center" }
const _hoisted_3 = { class: "textBlack" }
const _hoisted_4 = { class: "row justify-content-between mt-2 mb-1" }
const _hoisted_5 = { class: "col-auto" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = {
  key: 0,
  class: "col"
}
const _hoisted_9 = {
  key: 1,
  class: "col"
}

import { useAuthServices } from "@/composables/useAuthServices";
import { ref } from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import AuthCard from "./AuthCard.vue";
import { forgotPasswordStep1 } from "./forms/templates/ForgotPasswordStep1.template";
import { forgotPasswordStep2 } from "./forms/templates/ForgotPasswordStep2.template";
import { useValidations } from "@/composables/useValidations";


export default /*@__PURE__*/_defineComponent({
  __name: 'ForgotPassword',
  emits: ["goToSlide"],
  setup(__props, { emit: __emit }) {

const { forgotPassword, confirmForgotPassword } = useAuthServices();
const validations = useValidations();

const emit = __emit;

const isFirstStep = ref<boolean>(true);
const formRef = ref();
const formForgotPasswordStep1 = ref(
  forgotPasswordStep1(submitForgotPassword, validations)
);
const formForgotPasswordStep2 = ref(
  forgotPasswordStep2(submitForgotPassword, validations)
);

function goToLogin(): void {
  isFirstStep.value = true;
  formRef.value.resetForm({});
  emit("goToSlide", 1);
}

async function submitForgotPassword(): Promise<void> {
  const { values } = formRef.value;
  const { email, newPassword, confirmationCode } = values;
  if (isFirstStep.value) {
    isFirstStep.value = !(await forgotPassword({ email }));
  } else {
    await confirmForgotPassword(
      { email, newPassword, confirmationCode },
      goToLogin
    );
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AuthCard, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("login.forgotPassTitle")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[2] || (_cache[2] = _createElementVNode("i", { class: "bi bi-arrow-left-circle actionableLabel mr-1" }, null, -1)),
          _createElementVNode("span", {
            class: "actionableLabel",
            onClick: goToLogin
          }, _toDisplayString(_ctx.$t("login.name")), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (isFirstStep.value)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "actionableLabel",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (isFirstStep.value = false))
              }, _toDisplayString(_ctx.$t("login.haveACodeYet")), 1))
            : (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "actionableLabel",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (isFirstStep.value = true))
              }, _toDisplayString(_ctx.$t("login.haventACodeYet")), 1))
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (isFirstStep.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(GeneralForm, {
                ref_key: "formRef",
                ref: formRef,
                form: formForgotPasswordStep1.value,
                formName: "forgotPassword"
              }, null, 8, ["form"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(GeneralForm, {
                ref_key: "formRef",
                ref: formRef,
                form: formForgotPasswordStep2.value,
                formName: "forgotPassword"
              }, null, 8, ["form"])
            ]))
      ])
    ]),
    _: 1
  }))
}
}

})