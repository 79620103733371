<template>
  <GeneralCard>
    <template v-slot:content>
      <div class="containter">
        <div class="d-flex">
          <div class="image-container">
            <div class="bg-image d-flex flex-start">
              <img src="../../assets/dashboard/money-bag.png" alt="money-bag" />
            </div>
          </div>
          <div class="text-end w-100 mt-3">
            <h6 class="mb-0 totalBalance">
              {{ $t("home.dashboard.totalBalance") }}
            </h6>
            <p class="fw-bold fs-3">
              <TextFormatter
                :value="props.totalBalance"
                formatType="currency"
              ></TextFormatter>
            </p>
          </div>
        </div>
        <div class="mt-4 d-flex flex-start">
          <p class="p-2 rounded d-inline percentage">
            <TextFormatter
              :value="props.growth"
              formatType="percentage"
            ></TextFormatter>
            <i class="bi bi-graph-up-arrow pl-1 d-inline"></i>
          </p>
        </div>
      </div>
    </template>
  </GeneralCard>
</template>

<script setup lang="ts">
import GeneralCard from "../globals/cards/GeneralCard.vue";
import TextFormatter from "../globals/UiTools/TextFormatter.vue";
import { defineProps, PropType } from "vue";

const props = defineProps({
  totalBalance: { type: String, required: true },
  growth: { type: String, required: true },
});
</script>

<style lang="scss">
.totalBalance {
  color: #e9627d;
  font-size: 17px;
}

.image-container {
  position: relative;
  width: 180px;
  margin: -8px -18px;
}

.bg-image {
  display: block;
  background: #f2eafa;
  border-bottom-right-radius: 50%;
  border-top-left-radius: $defaultBorder;
  height: 120px;
}

.percentage {
  color: #00b2a5;
  background-color: #ddf5f0;
}

.percentage:hover {
  transform: scale(1.05);
}
</style>
