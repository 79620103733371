<template>
  <div class="row">
    <div class="col-12">
      <h1 style="display: flex">{{ $t("profiles.title") }}</h1>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-start">
              <button
                type="button"
                class="btn btn-primary"
                @click="() => openOffcanvasForm()"
              >
                <i class="bi bi-person-plus me-2"></i>
                {{ $t("profiles.add") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GeneralOffcanvas
    canvasId="customFieldCanvas"
    ref="offCanvasRef"
    :canvasName="$t('profiles.form.title')"
    @closed="customFieldsClosed"
    ><template v-slot:content>
      <CreateOrUpdateProfile
        ref="form"
        :initial-values="profileTemp"
        @handle-submit="handleProfileSubmit"
      ></CreateOrUpdateProfile>
    </template>
  </GeneralOffcanvas>
  <ConfirmModal
    :title="confirmModalTitleTranslate"
    ref="confirmationModalRef"
    :message="confirmationQuestion"
    :confirmAction="handleConfirm"
  ></ConfirmModal>
  <GeneralTable
    v-if="profilesHeaderTable"
    dataSource="/profiles/"
    :filters="tableFilters"
    :headers="profilesHeaderTable"
    name="usersTable"
    :label="$t('global.tables.users.label')"
    ref="profileTable"
  ></GeneralTable>
</template>

<script lang="ts" setup>
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import CreateOrUpdateProfile from "@/components/profiles/CreateOrUpdateProfile.vue";
import { usePermissionsServices } from "@/composables/usePermissionsServices";
import { useProfilesServices } from "@/composables/useProfilesServices";
import { User } from "@/store/auth/models/User.model";
import { Profile } from "@/store/profiles/models/Profile.model";
import { Offcanvas } from "bootstrap";
import { computed, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { ProfileHeader } from "./headers/ProfileHeader.headers";

const { permissions, getPermissions } = usePermissionsServices();
const { deleteProfile } = useProfilesServices();
const form = ref();
const profileTable = ref();
const offCanvasRef = ref();
const profilesHeaderTable = ref();
const profileTemp: Ref<Profile | null> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<"edit" | "delete"> = ref();
const confirmModalTitleTranslate: Ref<string> = ref("");
const { t } = useI18n();

watch(
  permissions,
  async () => {
    await getPermissions();
    profilesHeaderTable.value = ProfileHeader(
      optionEdit,
      optionDelete,
      permissions.value
    );
  },
  { deep: true, immediate: true }
);

const customFieldsClosed = () => {
  return form.value.resetForms && form.value.resetForms();
};

const tableFilters = ref([]);

function handleProfileSubmit(data) {
  const { profile, isCreating } = data;
  if (isCreating) {
    profileTable.value.currentData = [
      profile,
      ...profileTable.value.currentData,
    ];
  } else {
    profileTable.value.currentData[lastRowIndex.value] = {
      ...profileTable.value.currentData[lastRowIndex.value],
      ...profile,
    };
  }
  closeOffcanvasForm();
}

const confirmationQuestion = computed(() => {
  return {
    value: `profiles.${question.value}`,
    params: {
      name: profileTemp.value?.name || "",
    },
    needsTranslate: true,
  };
});

const question = computed(() => {
  if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

function optionEdit(profile: Profile, index: number) {
  openOffcanvasForm();
  action.value = "edit";
  profileTemp.value = { ...profile };
  lastRowIndex.value = index;
}

function optionDelete(profile: Profile, index: number) {
  action.value = "delete";
  confirmModalTitleTranslate.value = t("global.warningDelete");
  profileTemp.value = { ...profile };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

async function handleConfirm() {
  let profile = null;
  if (action.value === "delete") {
    const userDeleted = await deleteProfile(profileTemp.value);
    if (userDeleted) {
      profileTable.value.currentData = profileTable.value.currentData.filter(
        (profile: User, index) => {
          return index !== lastRowIndex.value;
        }
      );
    }
  }
}

function openOffcanvasForm() {
  profileTemp.value = null;
  const offcanvasInstance = Offcanvas.getOrCreateInstance(
    offCanvasRef.value.$el
  );
  offcanvasInstance.show();
}

function closeOffcanvasForm() {
  const offcanvasInstance = Offcanvas.getOrCreateInstance(
    offCanvasRef.value.$el
  );
  offcanvasInstance.hide();
}
</script>

<style lang="scss" scoped>
.btn-gear {
  color: white;
  background-color: #7451c2;
  border-color: #7451c2;
}
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
