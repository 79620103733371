export const es = {
  language: "ES",
  name: "Español",
  global: {
    warningDelete: "Atención de eliminación",
    confirm: "Confirmar",
    cancel: "Cancelar",
    back: "Atrás",
    employee: "Empleado",
    earnings: "Ingresos",
    inputs: {
      ruleName: "Reglas",
      select: "Seleccione una opción",
      rules: {
        required: "Debe tener un valor",
        upperCaseLimited: "Debe contener al menos {quantity} letras mayusculas",
        lowerCaseLimited: "Debe contener al menos {quantity} letras minusculas",
        especialCharactersLimited:
          "Debe contener al menos {quantity} caracteres especiales",
        numberCharactersLimited:
          "Debe contener al menos {quantity} caracteres numericos",
        minQuantity: "Debe contener al menos {quantity} caracteres",
        numberCharacters: "Debe estar compuesta de caracteres numericos",
        email: "Debe ser una dirección de correo válida",
        onlyNumber: "Debe ser un número",
        positiveNumber: "Debe ser un número positivo",
        isOptional: "Es opcional",
        isLess: "Debe ser menor o igual que {num}",
        isGreater: "Debe ser mayor o igual que {num}",
      },
    },
    tables: {
      resultsPerPage: "Resultados por página",
      users: {
        label: "Tabla de Usuarios",
        id: "id",
        name: "Nombre",
        email: "Correo",
        profile: "Perfil",
        createdAt: "Fecha Creación",
        updatedAt: "Fecha de Actualización",
        status: "Estado",
        actions: "Acciones",
        actionList: {
          enable: "Activar",
          disable: "Desactivar",
          edit: "Editar",
          delete: "Eliminar",
        },
      },
      emptyData:
        "Reajusta los filtros, no hay datos que correspondan a estos criterios",
      selectNull: "Seleccionar {entity}",
    },
    search: "Buscar",
    unknownError:
      "Ha ocurrido un error inesperado, por favor, contacta a soporte",
    statuses: {
      active: "Activo",
      pending: "Pendiente",
      deny: "Rechazado",
      new: "Nuevo",
      inactive: "Inactivo",
    },
    permissions: {
      dashboard: "Dashboard",
      users: "Usuarios",
      profiles: "Perfiles",
      customers: "Clientes",
      providers: "Proveedores",
      products: "Productos",
      raw_materials: "Materias Primas", //revisemos esto por favor
      product_family: "Familia de Productos", //revisemos esto por favor
      measures: "Medidas",
      orders: "Pedidos",
      shipping: "Envios",
      inventories: "Inventarios",
      workplace: "Centro de Trabajo",
      reports: "Reportes",
      setting: "Configuración",
    },
  },
  login: {
    access_title: "ACCESO A QUICK PALLET",
    title: "INGRESA TU CORREO Y PASSWORD",
    name: "Iniciar sesión",
    email: "Correo",
    password: "Contraseña",
    newPassword: "Nueva contraseña",
    submit: "ENTRAR",
    viewsSubmit: "ENVIAR",
    request: "Solicitar",
    errors: {
      credentialsUnknown:
        "Correo electronico o contraseña incorrectos, intentalo de nuevo.",
      invalidValidationData: "Datos de validación incorrectos",
      incorrectForgotPassData:
        "Este correo no ha sido registrado, intenta con una direccion de correo valida.",
      incorrectValidateEmailData:
        "Este correo no ha sido registrado, intenta con una direccion de correo valida.",
      attemptsLimitExceeded:
        "Lo has intentado muchas veces, intenta de nuevo mas tarde.",
      expiredCode: "El codigo ha expirado, intenta solicitar uno nuevo",
      incorrectValidationData: "Datos incorrectos",
      isValidatedYet: "Este correo ya está validado",
    },
    validationSuccess: "Validación exitosa",
    resendValidationSuccess:
      "Enviamos un codigo de confirmación a tu correo electronico!",
    forgotPassSuccess:
      "Enviamos un codigo de confirmación a tu correo electronico!",
    confirmForgotPassSuccess:
      "Tu contraseña ha cambiado. ahora puedes iniciar sesión!",
    forgotPassTitle: "Recupera tu contraseña!",
    forgotPassword: "Has olvidado tu contraseña?",
    validateEmailTitle: "Valida tu correo electronico",
    validateEmail: "Deseas validar tu email?",
    resendValidateEmail: "Solicitar nuevo codigo",
    confirmationCode: "Codigo de verificación",
    anotherAccessOptions: "O continúa con",
    haveACodeYet: "Ya tengo un código!",
    haventACodeYet: "No tengo un código!",
  },
  accounts: {
    errors: {
      gettingAccount:
        "Ha ocurrido un error obteniendo la información de la cuenta, por favor, contacta a soporte.",
    },
  },
  users: {
    errors: {
      accessExpired: "Tu sesión ha expirado, vuelve a iniciar sesión.",
      unknownError:
        "Ha ocurrido un error inesperado al obtener el usuario, contacta a soporte",
      userAlreadyExists: "El usuario ya existe",
    },
    sucess: {
      userCreated: "Usuario creado",
      userUpdated: "Usuario actualizado",
      userDeleted: "Usuario borrado",
      statusUpdated: "Estado del usuario actualizado",
    },
    warnings: {
      status: "Atención de cambio de estado",
    },
    form: {
      title: "Crear usuario",
      userName: "Nombre",
      email: "Correo electronico",
      password: "Contraseña",
      profile: "Perfil",
      initialStatus: "Estado inicial",
      addUser: "Agregar usuario",
      updateUser: "Actualizar usuario",
    },
    questionDelete:
      "¿Estás seguro de eliminar el usuario <strong>{name}</strong>?",
    questionEnable:
      "¿Estás seguro de habilitar al usuario <strong>{name}</strong>?",
    questionDisable:
      "¿Estás seguro de deshabilitar al usuario <strong>{name}</strong>?",
  },

  home: {
    navbar: {
      elements: {
        home: "INICIO",
        usersManagment: {
          name: "GESTIÓN DE USUARIOS",
          dropdowns: {
            users: "USUARIOS",
            profiles: "PERFILES",
          },
        },
        crm: {
          name: "CRM",
          dropdowns: {
            customers: "CLIENTES",
            providers: "PROVEEDORES",
          },
        },
        productsManagment: {
          name: "GESTIÓN DE PRODUCTOS",
          dropdowns: {
            products: "PRODUCTOS",
            rawMaterials: "MATERIAS PRIMAS",
            productFamily: "FAMILIAS DE PROD.",
            measures: "MEDIDAS",
          },
        },
        operations: {
          name: "OPERACIONES",
          dropdowns: {
            orders: "PEDIDOS",
            shipping: "ENVÍOS",
            inventories: "INVENTARIOS",
          },
        },
        workplace: "CENTRO DE TRABAJO",
        reports: "REPORTES",
        settings: "CONFIGURACIÓN",
      },
    },
    dashboard: {
      title: "Sistema para administración",
      statistics: "Estadísticas",
      customers: "CLIENTES",
      sales: "VENTAS",
      soldsDetails: "DETALLES DE VENTAS",
      thisWeek: "ESTA SEMANA",
      totalBalance: "Balance Total",
      lastTransactions: "ÚLTIMOS MOVIMIENTOS",
    },
  },
  catalogs: {
    USERS_STATUS_CLASS: "Estado de los usuarios",
    EMPLOYEES_STATUS_CLASS: "Estado de los empleados",
  },
  customFields: {
    title: "Campos personalizados",
    name: "Nombre del campo",
    required: "Requerido",
    type: "Tipo de campo",
    types: {
      text: "Texto",
      number: "Número",
      select: "Selección única",
      multipleSelect: "Selección múltiple",
      date: "Fecha",
    },
    default: "Valor por defecto",
    regularExpression: "Expresión Regular",
    unitOfMeasurement: "Unidad de Medida",
    min: "Valor mínimo",
    max: "Valor máximo",
    negativeInfinity: "-Infinito",
    positiveInfinity: "+Infinito",
    selectionLimit: "Cantidad de selecciones simultáneas",
    values: "Ingrese valores",
    addField: "Agregar Campo",
    save: "Guardar",
    translates: "Traducciones del valor",
    settings: "Configuraciones",
    optionsValues: "Valores de opción",
    errors: {
      fieldAlreadyExists: "Campo personalizado ya exitente.",
    },
    sucess: {
      customFieldCreated: "Campo personalizado creado",
      customFieldUpdated: "Campo personalizado actualizado",
      customFieldDeleted: "Campo personalizado borrado",
    },
    yupRules: {
      isEmailYup: "email",
      minCharsYup: "carácteres mínimos",
      minSpecialCharsYup: "carácteres especiales mínimos",
      minNumbersYup: "números mínimos",
      minUpperCaseYup: "mayúsculas mínimas",
      minLowerCaseYup: "minúsculas mínimas",
      positiveYup: "positivo",
      integerYup: "entero",
      isGreaterYup: "mayor o igual que",
      isLessYup: "menor o igual que",
      isPositiveNumberYup: "positivo",
    },
    quantity: `Cantidad de {name}`,
    addOption: "Ingrese opción {optionIndex}",
    updateField: "Actualizar campo",
    questionDelete:
      "¿Estás seguro de eliminar el campo personalizado llamado <strong>{name}</strong>?",
    isCurrency: "Es una divisa",
    validations: "Validaciones",
  },
  profiles: {
    title: "Perfiles",
    add: "Agregar Perfil",
    table: {
      id: "Id",
      name: "Nombre",
      description: "Descripción",
      createdAt: "Fecha de Creación",
      updatedAt: "Fecha de Edición",
      permissions: "Permisos",
      actions: "Acciones",
    },
    form: {
      title: "Crear Perfil",
      name: "Nombre",
      description: "Descripción",
      permissions: "Permisos",
      add: "Agregar Perfil",
      edit: "Editar Perfil",
    },
    questionDelete:
      "¿Estás seguro de eliminar el perfil <strong>{name}</strong>?",
  },
  measures: {
    title: "Medidas",
    errors: {
      unknownError:
        "Ha ocurrido un error inesperado al obtener la medida, contacta a soporte",
      alreadyExists: "Las medidas ya existen",
    },
    sucess: {
      userCreated: "Medidas creado",
      userUpdated: "Medidas actualizadas",
      userDeleted: "Medidas borradas",
    },
    warnings: {
      status: "Atención de cambio de medidas",
    },
    form: {
      title: "Crear medidas",
      userName: "Nombre",
      email: "Correo electronico",
      password: "Contraseña",
      profile: "Perfil",
      initialStatus: "Estado inicial",
      addUser: "Agregar usuario",
      updateUser: "Actualizar usuario",
    },
    tables: {
      id: "Id",
      name: "Nombre",
      description: "Descripción",
      createdAt: "Fecha de Creación",
      updatedAt: "Fecha de Actualización",
      actions: "Acciones",
      actionList: {
        edit: "Editar",
        delete: "Borrar",
      },
    },
    questionDelete:
      "¿Estás seguro de eliminar las medidas <strong>{name}</strong>?",
  },
  rawMaterials: {
    title: "Materias Primas",
    errors: {
      unknownError:
        "Ha ocurrido un error inesperado al obtener la materia prima, contacta a soporte",
      alreadyExists: "La materia prima ya existe",
    },
    sucess: {
      userCreated: "Materia prima creada",
      userUpdated: "Materia prima actualizadas",
      userDeleted: "Materia prima borradas",
    },
    warnings: {
      status: "Atención de cambio de materia prima",
    },
    form: {
      title: "Crear materia prima",
      userName: "Nombre",
      email: "Correo electronico",
      password: "Contraseña",
      profile: "Perfil",
      initialStatus: "Estado inicial",
      addUser: "Agregar usuario",
      updateUser: "Actualizar usuario",
    },
    tables: {
      id: "Id",
      code: "Código",
      name: "Nombre",
      description: "Descripción",
      unitOfMeasure: "Unidad de Medida",
      materialType: "Tipo de Material",
      unitCost: "Costo Unitario",
      quantityAvailable: "Cantidad Disponible",
      minimumStock: "Stock Mínimo",
      warehouseLocation: "Ubicación en Almacén",
      createdAt: "Fecha de Creación",
      updatedAt: "Fecha de Actualización",
      isActive: "Activo",
      supplier: "Proveedor",
      actions: "Acciones",
      actionList: {
        edit: "Editar",
        delete: "Borrar",
      },
    },
    questionDelete:
      "¿Estás seguro de eliminar las medidas <strong>{name}</strong>?",
  },
  productFamily: {
    title: "Familia de productos",
    add: "Agregar Familia de Productos",
    table: {
      id: "Id",
      name: "Nombre",
      description: "Descripción",
      createdAt: "Fecha de Creación",
      actions: "Acciones",
      actionList: {
        edit: "Editar",
        delete: "Borrar",
      },
    },
    form: {
      title: "Crear Perfil",
      name: "Nombre",
      description: "Descripción",
      permissions: "Permisos",
      add: "Agregar Perfil",
      edit: "Editar Perfil",
    },
    questionDelete:
      "¿Estás seguro de eliminar el perfil <strong>{name}</strong>?",
  },
  products: {
    title: "Productos",
    add: "Agregar Producto",
    table: {
      id: "Id",
      name: "Nombre",
      description: "Descripción",
      createdAt: "Fecha de Creación",
      measuresName: "Medidas",
      familyProductsName: "Familia de Productos",
      actions: "Acciones",
      actionList: {
        edit: "Editar",
        delete: "Borrar",
      },
    },
    form: {
      title: "Crear Perfil",
      name: "Nombre",
      description: "Descripción",
      permissions: "Permisos",
      add: "Agregar Perfil",
      edit: "Editar Perfil",
    },
    questionDelete:
      "¿Estás seguro de eliminar el perfil <strong>{name}</strong>?",
  },
};
