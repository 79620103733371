<template>
  <div class="container text-start">
    <form @submit="onSubmit" class="row" :key="formName">
      <div
        class="col-12"
        v-for="(section, index) in form?.sections"
        :key="`${formName}Section-${index}`"
      >
        <div
          v-if="section.conditions ? section.conditions() : true"
          class="row"
        >
          <div v-if="section.name" class="row mt-3" id="anotherOptionsDivider">
            <div class="col-1"><hr /></div>
            <div class="col-auto p-0 dividerText d-flex align-items-center">
              <b class="m-0 fs-5">
                <ProcessText :value="section.name"></ProcessText>
              </b>
            </div>
            <div class="col pl-1"><hr /></div>
          </div>

          <div
            class="co-12"
            v-for="(field, fieldIndex) in section?.fields"
            :key="`${formName}Section-${index}-Field-${fieldIndex}`"
          >
            <div class="row">
              <div
                class="col-12 mt-2"
                v-if="field.conditions ? field.conditions() : true"
              >
                <InputMaster
                  :inputName="field.inputName"
                  :path="field.path"
                  :label="field.label"
                  :type="field.type"
                  :icon="field.icon"
                  :placeholder="field.placeholder"
                  :options="field.options"
                  :buttons="field.buttons"
                  :metadata="field.metadata"
                  :errorBag="errorBag"
                  :disabled="field.disabled"
                ></InputMaster>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <slot></slot>
          </div>
          <div class="col-12">
            <div class="col-12 p-0 mt-2">
              <ButtonMaster
                :text="form?.submit?.text"
                :isLoading="isSubmitting"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import InputMaster from "@/components/globals/inputs/InputMaster.vue";
import { Form as FormType } from "@/shared/globals/forms/interfaces/Form.interface";
import { YupValidator } from "@/shared/globals/helpers/YupValidator";
import { useForm } from "vee-validate";
import {
  defineExpose,
  defineProps,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import ButtonMaster from "../buttons/ButtonMaster.vue";
import ProcessText from "../UiTools/ProcessText.vue";

const props = defineProps({
  form: { type: Object as PropType<FormType>, required: true },
  formName: { type: String, required: true },
});

const yupValidator = ref(createYupValidator());

const formProps = useForm();
const { values, handleSubmit, isSubmitting, setErrors } = formProps;
const errorBag = ref();

const onSubmit = handleSubmit(async () => {
  const isValid = await validateForm();
  if (isValid) {
    await props.form.submit.action();
  }
});

async function validateForm(): Promise<boolean> {
  const validations = await yupValidator.value.getValidations(values);
  const isValid = Object.values(validations).every((validation) => {
    const value = validation as { error: string; valid: boolean }[];
    return value.every((v) => v.valid);
  });
  if (!isValid) {
    setErrors(validations);
  }
  return isValid;
}

function createYupValidator() {
  const yupValidator = new YupValidator();
  const schema = props.form
    ? props.form.sections.reduce((acc, section) => {
        if (!section.conditions || section.conditions()) {
          section.fields.reduce((acc, field) => {
            if ((!field.conditions || field.conditions()) && field.methodsYup) {
              const keys = field.path.split(".");
              keys.reduce((acc, key, index, arr) => {
                if (index === arr.length - 1) {
                  acc[key] = field.methodsYup();
                }
                return acc[key] || (acc[key] = {});
              }, acc);
            }
            return acc;
          }, acc);
        }

        return acc;
      }, {})
    : {};
  yupValidator.addSchema(schema, []);
  return yupValidator;
}

watch(
  values,
  () => {
    yupValidator.value = createYupValidator();
  },
  { deep: true }
);

watch(values, async () => {
  const error = await yupValidator.value.getValidations(values);
  errorBag.value = error;
});

onMounted(async () => {
  const error = await yupValidator.value.getValidations(values);
  errorBag.value = error;
});

defineExpose({ ...formProps, validateForm });
</script>

<style lang="scss"></style>
