import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/dashboard/money-bag.png'


const _hoisted_1 = { class: "containter" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "text-end w-100 mt-3" }
const _hoisted_4 = { class: "mb-0 totalBalance" }
const _hoisted_5 = { class: "fw-bold fs-3" }
const _hoisted_6 = { class: "mt-4 d-flex flex-start" }
const _hoisted_7 = { class: "p-2 rounded d-inline percentage" }

import GeneralCard from "../globals/cards/GeneralCard.vue";
import TextFormatter from "../globals/UiTools/TextFormatter.vue";
import { PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BalanceCard',
  props: {
  totalBalance: { type: String, required: true },
  growth: { type: String, required: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GeneralCard, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "image-container" }, [
            _createElementVNode("div", { class: "bg-image d-flex flex-start" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "money-bag"
              })
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h6", _hoisted_4, _toDisplayString(_ctx.$t("home.dashboard.totalBalance")), 1),
            _createElementVNode("p", _hoisted_5, [
              _createVNode(TextFormatter, {
                value: props.totalBalance,
                formatType: "currency"
              }, null, 8, ["value"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, [
            _createVNode(TextFormatter, {
              value: props.growth,
              formatType: "percentage"
            }, null, 8, ["value"]),
            _cache[1] || (_cache[1] = _createElementVNode("i", { class: "bi bi-graph-up-arrow pl-1 d-inline" }, null, -1))
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})