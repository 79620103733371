import { UserInterface } from "@/store/auth/interfaces/User.interface";
import { Menu } from "../../../shared/globals/menu/types/Menu.type";
import { TableHeader } from "../../../shared/globals/tables/interfaces/TableHeader.interface";

export const ProfileHeader = (
  optionEdit,
  optionDelete,
  permissions
): TableHeader[] => {
  return [
    {
      sortable: true,
      value: { value: "profiles.table.id", needsTranslate: true },
      key: "id",
      mappedKey: "id",
      columnType: "number",
      width: "380px",
    },
    {
      sortable: true,
      value: { value: "profiles.table.name", needsTranslate: true },
      key: "name",
      mappedKey: "name",
      columnType: "text",
      width: "100px",
    },
    {
      sortable: true,
      value: { value: "profiles.table.description", needsTranslate: true },
      key: "description",
      mappedKey: "description",
      columnType: "text",
      width: "100px",
    },
    {
      sortable: true,
      value: { value: "profiles.table.createdAt", needsTranslate: true },
      key: "createdAt",
      mappedKey: "createdAt",
      columnType: "date",
      width: "150px",
    },
    {
      sortable: true,
      value: { value: "profiles.table.updatedAt", needsTranslate: true },
      key: "updatedAt",
      mappedKey: "updatedAt",
      columnType: "date",
      width: "190px",
    },
    {
      sortable: true,
      value: { value: "profiles.table.permissions", needsTranslate: true },
      key: "permissions",
      mappedKey: "permissions",
      columnType: "permission",
      width: "190px",
      metadata: {
        permissions,
      },
    },
    {
      sortable: true,
      value: { value: "profiles.table.actions", needsTranslate: true },
      columnType: "action",
      options: (user: UserInterface, index: number): Menu => {
        let options = [
          {
            label: {
              value: "global.tables.users.actionList.edit",
              needsTranslate: true,
            },
            id: 1,
            action: () => optionEdit(user, index),
          },
        ];
        if (user?.account?.id) {
          options = [
            ...options,
            {
              label: {
                value: "global.tables.users.actionList.delete",
                needsTranslate: true,
              },
              id: 2,
              action: () => optionDelete(user, index),
            },
          ];
        }
        return {
          options,
        };
      },
      width: "95px",
    },
  ];
};
