import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "col-12" }

import { usePermissionsServices } from "@/composables/usePermissionsServices";
import { useProfilesServices } from "@/composables/useProfilesServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { Profile } from "@/store/profiles/models/Profile.model";
import { merge } from "lodash";
import {
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { addTranslate } from "./forms/templates/AddTranslate.template";
import { CreateOrUpdateProfileTemplate } from "./forms/templates/CreateOrUpdateProfile.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateProfile',
  props: {
  initialValues: { type: Object as PropType<Profile>, required: false },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { permissions } = usePermissionsServices();
const { createProfile, updateProfile } = useProfilesServices();

const props = __props;

const initialValuesRef: Ref<Profile> = toRef(props, "initialValues");
const allValues: Ref<{ profile: Profile } | object> = ref({});
const formName: Ref<string> = ref();
const formMain = ref();
const formUserTemplateRef = ref(
  CreateOrUpdateProfileTemplate(
    handleSubmit,
    !initialValuesRef.value,
    actionTranslate,
    permissions.value
  )
);
const formTranslateTemplate: Ref<Form> = ref();
const translateModal = ref();
const translateForm = ref();

const emit = __emit;

watch([initialValuesRef, permissions], () => {
  formUserTemplateRef.value = CreateOrUpdateProfileTemplate(
    handleSubmit,
    !initialValuesRef.value,
    actionTranslate,
    permissions.value
  );
  if (!initialValuesRef.value) {
    resetForms();
  }
});

async function submitTranslateForm() {
  await new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      allValues.value = merge({}, allValues.value, translateForm.value.values);
      translateModal.value.closeModal();
      resolve();
    }, 300);
  });
}

async function handleSubmit() {
  allValues.value = { ...allValues.value, ...formMain.value.values };
  const { profile } = allValues.value as { profile: Profile };
  const formattedProfile = formatValuesFromFormValues(profile);
  if (!initialValuesRef.value) {
    const newProfile = await createProfile(formattedProfile);
    if (newProfile) {
      emit("handleSubmit", { profile: newProfile, isCreating: true });
    }
  } else {
    const editedProfile = await updateProfile(formattedProfile);
    if (editedProfile) {
      emit("handleSubmit", { profile: editedProfile, isCreating: false });
    }
  }
}

function actionTranslate(fieldName) {
  formTranslateTemplate.value = addTranslate(submitTranslateForm, fieldName);
  formName.value = fieldName;
  translateModal.value.openModal();
  if (initialValuesRef.value) {
    const { metadata } = formatValuesFromInitialValues(initialValuesRef.value);
    translateForm.value?.setValues({ profile: { metadata } });
  }
}

function formatValuesFromFormValues(values) {
  return {
    ...values,
    permissions: values.permissions.map((p) => {
      return { id: p.value };
    }),
  };
}

function formatValuesFromInitialValues(values) {
  const { createdAt, updatedAt, ...profile } = {
    ...values,
    permissions: values.permissions.map((p) => {
      return { value: p.id };
    }),
  };
  return profile;
}

watch(
  initialValuesRef,
  async () => {
    await nextTick();
    resetForms();
    if (initialValuesRef.value) {
      const { metadata, ...profile } = formatValuesFromInitialValues(
        initialValuesRef.value
      );
      formMain.value?.setValues({ profile });
    }
  },
  { immediate: true, deep: true }
);

function resetForms() {
  formMain.value.resetForm();
  formUserTemplateRef.value.sections.forEach((section) => {
    section.fields.forEach((field) => {
      if (field.type === "select") {
        formMain.value?.setFieldValue(field.path, "");
      }
    });
  });
}

__expose({
  resetForms,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(GeneralForm, {
          ref_key: "formMain",
          ref: formMain,
          form: formUserTemplateRef.value,
          "form-name": "usersForm"
        }, null, 8, ["form"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(GeneralModal, {
          modalId: "translateModal",
          ref_key: "translateModal",
          ref: translateModal,
          modalName: `${_ctx.$t('customFields.translates')}`,
          "data-bs-backdrop": "false",
          class: "p-4"
        }, {
          default: _withCtx(() => [
            _createVNode(GeneralForm, {
              form: formTranslateTemplate.value,
              formName: formName.value,
              ref_key: "translateForm",
              ref: translateForm
            }, null, 8, ["form", "formName"])
          ]),
          _: 1
        }, 8, ["modalName"])
      ])
    ])
  ]))
}
}

})