import { useLanguageServices } from "@/composables/useLanguageServices";
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import { FieldType } from "@/shared/globals/forms/types/FieldType.type";
import { TooltipPosition } from "@/shared/globals/inputs/types/TooltipPosition.type";
import _ from "lodash";
import { getCatalogValue } from "../../helpers/Catalogs.helper";
import { formatNumberCurrency } from "../../helpers/Number.helper";
import { TableHeader } from "../interfaces/TableHeader.interface";
import { Row } from "../types/Row.type";

// eslint-disable-next-line
export function mapFromHeader(headers: TableHeader[], data: any[]): Row[] {
  const { lang } = useLanguageServices();

  return data.map((tableRows: Row) => {
    let defaultTableRows = tableRows;
    if (tableRows?.customFields) {
      const { customFields, ...tempTableRows } = tableRows;
      defaultTableRows = {
        ...tempTableRows,
        ...(customFields as Row),
      };
    }
    const row: Row = {};
    headers.forEach((header) => {
      switch (header.columnType) {
        case "userTag":
          row[header.key] = {
            firstName: _.get(defaultTableRows, header.mappedKey),
            lastName: _.get(
              defaultTableRows,
              header.mappedKey.replace("firstName", "lastName"),
              ""
            ),
          };
          break;
        case "currency":
          row[header.key] = +_.get(defaultTableRows, header.mappedKey, 0);
          break;
        case "catalog": {
          row[header.key] = getCatalogValue(
            header,
            _.get(defaultTableRows, header.mappedKey, 0)
          );
          break;
        }
        case "date":
          row[header.key] = new Date(_.get(defaultTableRows, header.mappedKey));
          break;
        case "number": {
          row[header.key] = _.get(defaultTableRows, header.mappedKey, "-");
          if (
            !(
              row[header.key] === null ||
              row[header.key] === undefined ||
              row[header.key] === ""
            ) &&
            !isNaN(+row[header.key])
          ) {
            if (
              header?.metadata?.customFieldMetadata.fieldSettings.isCurrency
            ) {
              row[header.key] = formatNumberCurrency(+row[header.key]);
            }
          } else {
            row[header.key] = "-";
          }
          break;
        }
        case "profile": {
          const profileIndex: number = _.get(
            defaultTableRows,
            header.mappedKey
          );
          const profile = header.metadata.profiles.find(
            (profile) => profile.id === Number(profileIndex)
          );
          row[header.key] = "-";
          if (profile) {
            row[header.key] = profile.name;
          }
          break;
        }
        case "permission": {
          const permissionsIds: Array<{ id: number }> = _.get(
            defaultTableRows,
            header.mappedKey
          );
          row[header.key] = permissionsIds.map((p) => {
            const permission = header.metadata.permissions.find(
              (permission) => permission.id == p.id
            );
            return (
              permission?.translates?.name?.[lang.value] ?? permission.name
            );
          });
          break;
        }
        case "action":
          break;
        case "select": {
          const key = _.get(defaultTableRows, header.mappedKey, false);
          if (key) {
            row[header.key] =
              header?.metadata?.customFieldMetadata?.language?.options?.[
                lang.value
              ]?.[key] ??
              header?.metadata?.customFieldMetadata?.options?.[key] ??
              "-";
          } else {
            row[header.key] = "-";
          }
          break;
        }
        case "multiple-select": {
          const keys = _.get(defaultTableRows, header.mappedKey, 0);
          row[header.key] = "-";
          if (keys.length > 0) {
            row[header.key] = "";
            const values = keys.reduce((acc, { value: key }) => {
              if (
                Object.keys(
                  header.metadata.customFieldMetadata.options
                ).includes(key)
              ) {
                acc[key] =
                  header?.metadata?.customFieldMetadata?.language?.options?.[
                    lang.value
                  ]?.[key] ??
                  header?.metadata?.customFieldMetadata?.options[key];
              }
              return acc;
            }, {});
            Object.keys(values).forEach((key, index) => {
              row[header.key] =
                row[header.key] + `${index > 0 ? "," : ""} ${values[key]}`;
            });
            if (Object.keys(values).length === 0) {
              row[header.key] = "-";
            }
          }
          break;
        }
        default:
          row[header.key] = _.get(defaultTableRows, header.mappedKey, "-");
          row[header.key] = row[header.key] !== "" ? row[header.key] : "-";
          break;
      }
    });
    return row;
  });
}
const columnTypeToFieldTypeMap: Record<string, FieldType> = {
  text: "text",
  number: "number",
  date: "date",
  currency: "currency",
  catalogStatus: "select",
  catalog: "select",
  userTag: "text",
  action: "text",
  image: "text",
  profile: "select",
};

export function generateFilterField(header: TableHeader): Field {
  const fieldType: FieldType =
    columnTypeToFieldTypeMap[header.columnType] || "text";

  const options = header.metadata?.filterMetadata?.options;

  const tooltipPosition: TooltipPosition = "auto";

  return {
    path: `filters.${header.key}`,
    inputName: header.key,
    type: fieldType,
    options,
    metadata: {
      tooltipPosition,
    },
  };
}
