import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-2" }
const _hoisted_3 = { class: "fs-2" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "row g-2 justify-content-between" }
const _hoisted_8 = { class: "col-auto" }
const _hoisted_9 = { class: "col-auto" }
const _hoisted_10 = {
  class: "row",
  id: "anotherOptionsDivider"
}
const _hoisted_11 = { class: "col-auto p-0 dividerText d-flex align-items-center" }
const _hoisted_12 = { class: "m-0 fs-6" }

import { useAuthServices } from "@/composables/useAuthServices";
import { useValidations } from "@/composables/useValidations";
import { ref } from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import AuthCard from "./AuthCard.vue";
import { LoginUserForm } from "./forms/templates/loginUser.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginBox',
  emits: ["goToSlide"],
  setup(__props, { emit: __emit }) {

const validations = useValidations();
const { login } = useAuthServices();

const emit = __emit;

const formRef = ref();
const formTemplate = ref(LoginUserForm(submitLogin, validations));

const initialValues = { email: "", password: "" };

async function submitLogin(): Promise<void> {
  const { values } = formRef.value;
  await login(values, goToForgotPassword);
}

function goToForgotPassword(): void {
  formRef.value.resetForm();
  emit("goToSlide", 0);
}
function goToValidateEmail(): void {
  formRef.value.resetForm({ values: initialValues });
  emit("goToSlide", 2);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AuthCard, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("login.access_title")), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("login.title")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(GeneralForm, {
            ref_key: "formRef",
            ref: formRef,
            form: formTemplate.value,
            formName: "loginUser"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", {
                    class: "actionableLabel",
                    onClick: goToForgotPassword,
                    "data-bs-target": "#carouselAuthViews",
                    "data-bs-slide-to": "1"
                  }, _toDisplayString(_ctx.$t("login.forgotPassword")), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", {
                    class: "actionableLabel",
                    onClick: goToValidateEmail
                  }, _toDisplayString(_ctx.$t("login.validateEmail")), 1)
                ])
              ])
            ]),
            _: 1
          }, 8, ["form"])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col pr-1" }, [
          _createElementVNode("hr")
        ], -1)),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("login.anotherAccessOptions")), 1)
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col pl-1" }, [
          _createElementVNode("hr")
        ], -1))
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "row justify-content-around pt-2 pb-3",
        id: "anotherOption"
      }, [
        _createElementVNode("div", { class: "col" }, [
          _createElementVNode("button", { class: "google-button btn extraOptionButton p-0 w-100 p-2" }, [
            _createElementVNode("img", {
              class: "bg-transparent",
              src: "images/icon/brands/icon-google.svg",
              width: "30px",
              alt: "googleLogo"
            })
          ])
        ])
      ], -1))
    ]),
    _: 1
  }))
}
}

})