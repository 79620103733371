import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "row g-2" }
const _hoisted_6 = { class: "col-12 d-flex justify-content-start" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "row g-2" }
const _hoisted_9 = { class: "col-12 d-flex justify-content-end" }

import CustomFields from "@/components/customFields/CustomFields.vue";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import CreateOrUpdateUser from "@/components/users/CreateOrUpdateUser.vue";
import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useProfilesServices } from "@/composables/useProfilesServices";
import { useUsersServices } from "@/composables/useUsersServices";
import { User } from "@/store/auth/models/User.model";
import { Offcanvas } from "bootstrap";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { UsersHeader } from "./headers/UsersHeader.headers";

type formsName = "createUser" | "editUser" | "createCustomField";


export default /*@__PURE__*/_defineComponent({
  __name: 'UsersView',
  setup(__props) {

const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { profiles, getAllProfiles } = useProfilesServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();
const { enableUser, disableUser, deleteUser } = useUsersServices();
const form = ref();
const userTable = ref();
const offCanvasRef = ref();
const usersHeaderTable = ref();
const formName: Ref<formsName> = ref();
const userTemp: Ref<User | null> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<"edit" | "disable" | "enable" | "delete"> = ref();
const confirmModalTitleTranslate: Ref<string> = ref("");
const { t } = useI18n();
onMounted(async () => {
  await getModuleCustomFields();
  await getAllProfiles();
  await getAllCatalogs();
  watch(
    customFields,
    () => {
      usersHeaderTable.value = UsersHeader(
        optionEdit,
        optionDelete,
        optionDisable,
        optionEnable,
        customFields.value,
        profiles.value,
        catalogs.value
      );
    },
    { immediate: true, deep: true }
  );
});

const customFieldsClosed = () => {
  return form.value.resetForms && form.value.resetForms();
};

const tableFilters = ref([]);

function handleUserSubmit(data) {
  const { user, isCreating } = data;
  if (isCreating) {
    userTable.value.currentData = [user, ...userTable.value.currentData];
  } else {
    userTable.value.currentData[lastRowIndex.value] = user;
  }
  closeOffcanvasForm();
}

function handleCustomFieldSubmit() {
  closeOffcanvasForm();
}

const confirmationQuestion = computed(() => {
  return {
    value: `users.${question.value}`,
    params: {
      name: userTemp.value?.name || "",
    },
    needsTranslate: true,
  };
});

const question = computed(() => {
  if (action.value === "disable") {
    return "questionDisable";
  } else if (action.value === "enable") {
    return "questionEnable";
  } else if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

function optionEdit(user: User, index: number) {
  openOffcanvasForm("editUser");
  action.value = "edit";
  userTemp.value = { ...user };
  lastRowIndex.value = index;
}

function optionDisable(user: User, index: number) {
  action.value = "disable";
  confirmModalTitleTranslate.value = t("global.warnings.status");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionEnable(user: User, index: number) {
  action.value = "enable";
  confirmModalTitleTranslate.value = t("users.warnings.status");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionDelete(user: User, index: number) {
  action.value = "delete";
  confirmModalTitleTranslate.value = t("global.warningDelete");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

async function handleConfirm() {
  let user = null;
  if (["enable", "disable"].includes(action.value)) {
    if (action.value === "enable") {
      user = await enableUser(userTemp.value);
    } else {
      user = await disableUser(userTemp.value);
    }
    userTable.value.currentData[lastRowIndex.value] = {
      ...userTemp.value,
      status: { id: user.status.id },
    };
  } else if (action.value === "delete") {
    const userDeleted = await deleteUser(userTemp.value);
    if (userDeleted) {
      userTable.value.currentData = userTable.value.currentData.filter(
        (user: User, index) => {
          return index !== lastRowIndex.value;
        }
      );
    }
  }
}

function openOffcanvasForm(newFormName: formsName) {
  userTemp.value = null;
  formName.value = newFormName;
  const offcanvasInstance = Offcanvas.getOrCreateInstance(
    offCanvasRef.value.$el
  );
  offcanvasInstance.show();
}

function closeOffcanvasForm() {
  const offcanvasInstance = Offcanvas.getOrCreateInstance(
    offCanvasRef.value.$el
  );
  offcanvasInstance.hide();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-12" }, [
        _createElementVNode("h1", { style: {"display":"flex"} }, "Usuarios")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[0] || (_cache[0] = () => openOffcanvasForm('createUser'))
                }, _cache[2] || (_cache[2] = [
                  _createElementVNode("i", { class: "bi bi-person-plus me-2" }, null, -1),
                  _createTextVNode(" Agregar Usuario ")
                ]))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn ml-1 btn-gear",
                  onClick: _cache[1] || (_cache[1] = () => openOffcanvasForm('createCustomField'))
                }, _cache[3] || (_cache[3] = [
                  _createElementVNode("i", { class: "bi bi-gear" }, null, -1)
                ]))
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(GeneralOffcanvas, {
      canvasId: "customFieldCanvas",
      ref_key: "offCanvasRef",
      ref: offCanvasRef,
      canvasName: 
      formName.value === 'createCustomField'
        ? _ctx.$t('customFields.title')
        : _ctx.$t('users.form.title')
    ,
      onClosed: customFieldsClosed
    }, {
      content: _withCtx(() => [
        (formName.value === 'createCustomField')
          ? (_openBlock(), _createBlock(CustomFields, {
              key: 0,
              ref_key: "form",
              ref: form,
              onCustomFieldSubmitted: handleCustomFieldSubmit
            }, null, 512))
          : _createCommentVNode("", true),
        (formName.value === 'createUser' || formName.value === 'editUser')
          ? (_openBlock(), _createBlock(CreateOrUpdateUser, {
              key: 1,
              ref_key: "form",
              ref: form,
              "initial-values": userTemp.value,
              onHandleSubmit: handleUserSubmit
            }, null, 8, ["initial-values"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["canvasName"]),
    _createVNode(ConfirmModal, {
      title: confirmModalTitleTranslate.value,
      ref_key: "confirmationModalRef",
      ref: confirmationModalRef,
      message: confirmationQuestion.value,
      confirmAction: handleConfirm
    }, null, 8, ["title", "message"]),
    (usersHeaderTable.value)
      ? (_openBlock(), _createBlock(GeneralTable, {
          key: 0,
          dataSource: "/users/",
          filters: tableFilters.value,
          headers: usersHeaderTable.value,
          name: "usersTable",
          label: _ctx.$t('global.tables.users.label'),
          ref_key: "userTable",
          ref: userTable
        }, null, 8, ["filters", "headers", "label"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})