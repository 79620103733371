<template>
  <GeneralCard>
    <template v-slot:content>
      <div class="row h-100">
        <div class="row">
          <div class="col">
            <div class="text-start">
              <p class="fs-4 fw-medium">
                {{ $t("home.dashboard.soldsDetails") }}
              </p>
              <p>
                <TextFormatter
                  :value="salesAmount"
                  formatType="currency"
                  class="fs-3 profit"
                >
                </TextFormatter>
                <span class="fs-6 fw-bold">{{
                  $t("home.dashboard.thisWeek")
                }}</span>

                <i class="bi bi-graph-up-arrow pl-1 icon"></i>
              </p>
            </div>
          </div>
          <div class="col-auto">
            <div class="mt-1">
              <OptionCard :options="options"></OptionCard>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <progress-bar :progress="progress"></progress-bar>
          </div>
        </div>
      </div>
    </template>
  </GeneralCard>
</template>

<script setup lang="ts">
import GeneralCard from "@/components/globals/cards/GeneralCard.vue";
import ProgressBar from "@/components/dashboard/ProgressBar.vue";
import TextFormatter from "../globals/UiTools/TextFormatter.vue";
import OptionCard from "../globals/cards/OptionCard.vue";
import { CardOption } from "@/shared/globals/cards/types/CardOption.type";

const options: CardOption[] = [
  {
    name: "This Week",
    callBack: () => {
      return;
    },
  },
  {
    name: "Last Week",
    callBack: () => {
      return;
    },
  },
  {
    name: "Last Month",
    callBack: () => {
      return;
    },
  },
];
const salesAmount = "45141";
const progress = 75;
</script>

<style scoped lang="scss">
.profit {
  color: #e95f2b;
  padding-right: 0.8rem;
}

.icon {
  color: #20a397;
}
</style>
