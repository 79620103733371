<template>
  <GeneralModal
    :modalId="`confirmModa${title}`"
    ref="modalRef"
    :modalName="`${title}`"
    data-bs-backdrop="false"
    class="p-4"
  >
    <div class="container d-flex flex-column justify-content-center">
      <div class="row mb-2">
        <div class="col">
          <ProcessText :value="message"></ProcessText>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ButtonField :button-field="cancelButton(handleCancel)"></ButtonField>
        </div>
        <div class="col">
          <ButtonField
            :button-field="confirmButton(handleConfirm)"
            :require-loading="true"
          ></ButtonField>
        </div>
      </div>
    </div>
  </GeneralModal>
</template>

<script lang="ts" setup>
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { Modal } from "bootstrap";
import { defineExpose, defineProps, PropType, ref } from "vue";
import ButtonField from "../buttons/ButtonField.vue";
import { cancelButton } from "../buttons/templates/CancelButton.template";
import { confirmButton } from "../buttons/templates/ConfirmButton.template";
import ProcessText from "../UiTools/ProcessText.vue";
import GeneralModal from "./GeneralModal.vue";

const props = defineProps({
  title: { type: String, required: true },
  message: { type: Object as PropType<LabelType>, required: true },
  cancelAction: {
    type: Function as PropType<() => Promise<void>>,
    required: false,
  },
  confirmAction: {
    type: Function as PropType<() => Promise<void>>,
    required: true,
  },
});

const modalRef = ref();

async function handleCancel() {
  if (props.cancelAction) {
    await props.cancelAction();
  }
  await closeConfirmationModal();
}

async function handleConfirm() {
  if (props.confirmAction) {
    await props.confirmAction();
  }
  await closeConfirmationModal();
}

async function closeConfirmationModal() {
  if (modalRef.value?.$el) {
    const modal = Modal.getOrCreateInstance(modalRef.value?.$el);
    modal.hide();
  }
}

async function openConfirmationModal() {
  const modal = Modal.getOrCreateInstance(modalRef.value?.$el);
  modal.show();
}

defineExpose({
  openConfirmationModal,
  closeConfirmationModal,
});
</script>

<style lang="scss" scoped></style>
