import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";

const { langValues } = useLanguageServices();

export const addTranslate = (action: () => void, fieldName: string): Form => {
  return {
    sections: [
      {
        fields: langValues.value.map((language) => {
          return {
            path: `profile.metadata.language.${fieldName}.${language.key}`,
            inputName: `profile.${fieldName}.${language.key}`,
            label: {
              value: `${language.name}`,
              needsTranslate: false,
            },
            type: "text",
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              const validation = [stringYup, requiredYup];
              return validation;
            },
          };
        }),
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: "customFields.save",
        needsTranslate: true,
      },
      action,
    },
  };
};
