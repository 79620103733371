import { UserInterface } from "@/store/auth/interfaces/User.interface";
import { Menu } from "../../../shared/globals/menu/types/Menu.type";
import { TableHeader } from "../../../shared/globals/tables/interfaces/TableHeader.interface";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";

export const ProductsHeader = (
  optionEdit,
  optionDelete,
  customFields
): TableHeader[] => {
  const customFieldsHeaders: TableHeader[] = customFields.map(
    (customField: CustomFieldInterface) => {
      return {
        sortable: false,
        value: { value: customField.name, needsTranslate: false },
        key: customField.id,
        mappedKey: customField.id,
        columnType: customField.type,
        width: "380px",
        ...(customField?.metadata
          ? { metadata: { customFieldMetadata: customField.metadata } }
          : {}),
        isCustomField: true,
      };
    }
  );
  return [
    {
      sortable: true,
      value: { value: "products.table.id", needsTranslate: true },
      key: "id",
      mappedKey: "id",
      columnType: "number",
      width: "380px",
    },
    {
      sortable: true,
      value: { value: "products.table.name", needsTranslate: true },
      key: "name",
      mappedKey: "name",
      columnType: "text",
      width: "100px",
    },
    {
      sortable: true,
      value: { value: "products.table.description", needsTranslate: true },
      key: "description",
      mappedKey: "description",
      columnType: "text",
      width: "100px",
    },
    {
      sortable: true,
      value: { value: "products.table.createdAt", needsTranslate: true },
      key: "createdAt",
      mappedKey: "createdAt",
      columnType: "date",
      width: "150px",
    },
    {
      sortable: true,
      value: { value: "products.table.measuresName", needsTranslate: true },
      key: "measure",
      mappedKey: "measure.name",
      columnType: "text",
      width: "190px",
    },
    {
      sortable: true,
      value: {
        value: "products.table.familyProductsName",
        needsTranslate: true,
      },
      key: "familyProductsName",
      mappedKey: "productFamily.name",
      columnType: "text",
      width: "190px",
    },
    ...customFieldsHeaders,
    {
      sortable: true,
      value: { value: "products.table.actions", needsTranslate: true },
      columnType: "action",
      options: (user: UserInterface, index: number): Menu => {
        const options = [
          {
            label: {
              value: "products.table.actionList.edit",
              needsTranslate: true,
            },
            id: 1,
            action: () => optionEdit(user, index),
          },
          {
            label: {
              value: "products.table.actionList.delete",
              needsTranslate: true,
            },
            id: 2,
            action: () => optionDelete(user, index),
          },
        ];
        return {
          options,
        };
      },
      width: "95px",
    },
  ];
};
