import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createBlock as _createBlock, isRef as _isRef, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelText as _vModelText, withKeys as _withKeys, vModelDynamic as _vModelDynamic } from "vue"

const _hoisted_1 = { class: "row g-1" }
const _hoisted_2 = { class: "col-12 text-start" }
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row g-0" }
const _hoisted_6 = {
  key: 0,
  class: "col-auto my-auto text-start"
}
const _hoisted_7 = ["id"]
const _hoisted_8 = ["id"]
const _hoisted_9 = ["id", "disabled"]
const _hoisted_10 = {
  value: "",
  disabled: "",
  selected: ""
}
const _hoisted_11 = ["value", "disabled"]
const _hoisted_12 = ["name", "id", "placeholder", "aria-describedby", "disabled"]
const _hoisted_13 = ["type", "name", "id", "placeholder", "aria-describedby", "disabled"]
const _hoisted_14 = ["id"]
const _hoisted_15 = { key: 0 }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = {
  key: 1,
  class: "col-auto my-auto text-start"
}
const _hoisted_18 = ["id"]

import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";
import { SelectOption } from "@/shared/globals/forms/interfaces/SelectOptions.interface";
import { FieldType } from "@/shared/globals/forms/types/FieldType.type";
import { IconType } from "@/shared/globals/forms/types/IconType.type";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { MetadataType } from "@/shared/globals/forms/types/MetadataType.type";
import { Dropdown } from "bootstrap";
import { merge } from "lodash";
import { useField } from "vee-validate";
import {
  computed,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import Multiselect from "vue-multiselect";
import ProcessText from "../UiTools/ProcessText.vue";
import ButtonField from "../buttons/ButtonField.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputMaster',
  props: {
  inputName: {
    type: String,
    required: true,
  },
  path: {
    type: String,
    required: true,
  },
  label: {
    type: Object as PropType<LabelType>,
    required: false,
  },
  type: {
    type: String as PropType<FieldType>,
    required: true,
    default: "text" as FieldType,
  },
  icon: {
    type: Object as PropType<IconType>,
    required: false,
  },
  placeholder: {
    type: Object as PropType<LabelType>,
    required: false,
  },
  options: {
    type: Object as PropType<Array<SelectOption>>,
    required: false,
  },
  buttons: {
    type: Array as PropType<Array<ButtonTemplate>>,
    required: false,
  },
  metadata: {
    type: Object as PropType<MetadataType>,
    required: false,
  },
  errorBag: {
    type: Object,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
  },
  reachableValue: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  emits: ["confirmValue"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;
const { t } = useI18n();
const dropdown: Ref<Dropdown> = ref(undefined);
const { value, meta, errors, ...propsField } = useField<string>(props.path);

const options = computed(() => {
  return props.options.map((option) => {
    return { value: option.value };
  });
});

function getCustomLabel(currentOption) {
  const option = props.options.find(
    (option) => option.value === currentOption.value
  );
  return `${
    option.name.needsTranslate ? t(option.name.value) : option.name.value
  }`;
}

function showDropdown(): void {
  if (
    props.errorBag &&
    props.errorBag[props.path] &&
    props.errorBag[props.path].length > 0
  ) {
    dropdown.value.show();
  }
}

function hideDropdown(): void {
  if (props.errorBag && props.errorBag[props.path]) {
    dropdown.value.hide();
  }
}

function emitValue() {
  if (props.reachableValue) {
    emit("confirmValue", value);
  }
}
const getInputClass = computed(() => {
  if (props.type == "checkbox") {
    return "form-check-input";
  }
  return "form-control";
});
const getPlaceholder = (): string => {
  if (!props.placeholder) return "";
  if (props.placeholder.needsTranslate) {
    return t(props.placeholder.value, props.placeholder.params);
  }
  return props.placeholder.value;
};

const mergedMetadata = computed(() => {
  const defaultMetadata = {
    tooltipPosition: "right",
    iconPosition: "end",
    labelPosition: "top",
    fieldSettings: {
      color: "blue",
    },
  };
  return merge({}, defaultMetadata, props.metadata);
});

const formButtons = computed(() => {
  return {
    startAppendButtons: props.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "start" && button.metadata.isAppend
    ),
    endAppendButtons: props.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "end" && button.metadata.isAppend
    ),
    startButtons: props.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "start" && !button.metadata.isAppend
    ),
    endButtons: props.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "end" && !button.metadata.isAppend
    ),
  };
});

const isValidClassComputed = computed(() => {
  if (
    props.errorBag &&
    ((errors.value.length > 0 && meta.validated) || value.value)
  ) {
    const isValid = props.errorBag[props.path]
      ? props.errorBag[props.path].every((e) => e.valid)
      : true;
    if (isValid) {
      return "is-valid";
    }
    return "is-invalid";
  }
  return "";
});

const windowWidth = ref();
window.addEventListener("resize", () => {
  windowWidth.value = window.innerWidth;
});

onMounted(() => {
  watch(
    windowWidth,
    () => {
      let tooltipPosition = mergedMetadata.value.tooltipPosition;
      if (
        windowWidth.value < 992 &&
        (tooltipPosition === "left" || tooltipPosition === "right")
      ) {
        tooltipPosition = "bottom";
      }
      dropdown.value = new Dropdown(`#${props.inputName}InputDropdown`, {
        display: "dynamic",
        popperConfig: function (defaultBsPopperConfig) {
          defaultBsPopperConfig.placement = tooltipPosition;
          return defaultBsPopperConfig;
        },
      });
    },
    { immediate: true }
  );
});

__expose({ value, meta, errors, ...propsField });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "container p-0",
    key: __props.path
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (mergedMetadata.value?.labelPosition === 'top' && props.label)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              for: props.inputName,
              class: "fw-medium fs-6"
            }, [
              _createVNode(ProcessText, {
                value: props.label
              }, null, 8, ["value"])
            ], 8, _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (mergedMetadata.value?.labelPosition === 'left' && props.label)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("span", {
                  class: "fw-medium fs-6 pr-1",
                  id: `${props.inputName}GroupPrepend`
                }, [
                  _createVNode(ProcessText, {
                    value: props.label
                  }, null, 8, ["value"])
                ], 8, _hoisted_7)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formButtons.value.startButtons, (startButton, startButtonIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-auto",
              key: `startButton${props.inputName}-${startButtonIndex}`
            }, [
              (
                startButton.conditions ? startButton.conditions(_unref(value)) : true
              )
                ? (_openBlock(), _createBlock(ButtonField, {
                    key: 0,
                    "button-field": startButton
                  }, null, 8, ["button-field"]))
                : _createCommentVNode("", true)
            ]))
          }), 128)),
          _createElementVNode("div", {
            class: _normalizeClass(`${props.type == 'checkbox' ? 'col-auto my-auto' : 'col'}`)
          }, [
            _createElementVNode("div", {
              id: `${props.inputName}InputDropdown`
            }, [
              _createElementVNode("div", {
                class: "d-flex justify-content-center align-items-center w-100 input-group",
                onMouseover: showDropdown,
                onMouseleave: hideDropdown
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formButtons.value.startAppendButtons, (startButton, startButtonIndex) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `startAppendButton-${props.inputName}-${startButtonIndex}`
                  }, [
                    (
                      startButton.conditions
                        ? startButton.conditions(_unref(value))
                        : true
                    )
                      ? (_openBlock(), _createBlock(ButtonField, {
                          key: 0,
                          "button-field": startButton
                        }, null, 8, ["button-field"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128)),
                (props.type == 'multiple-select')
                  ? (_openBlock(), _createBlock(_unref(Multiselect), {
                      key: 0,
                      modelValue: _unref(value),
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                      id: props.inputName,
                      "input-class": `${isValidClassComputed.value}`,
                      options: options.value,
                      multiple: true,
                      placeholder: _ctx.$t('global.inputs.select'),
                      label: "name",
                      selectLabel: "",
                      "track-by": "value",
                      "hide-selected": true,
                      "custom-label": getCustomLabel
                    }, null, 8, ["modelValue", "id", "input-class", "options", "placeholder"]))
                  : (props.type == 'select')
                    ? _withDirectives((_openBlock(), _createElementBlock("select", {
                        key: 1,
                        class: _normalizeClass(["form-select form-control", `${isValidClassComputed.value}`]),
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                        id: props.inputName,
                        disabled: __props.disabled,
                        onChange: emitValue
                      }, [
                        _createElementVNode("option", _hoisted_10, _toDisplayString(_ctx.$t("global.inputs.select")), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.options || [], (option) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: option.value,
                            value: option.value,
                            disabled: option.disabled
                          }, [
                            _createVNode(ProcessText, {
                              value: option.name
                            }, null, 8, ["value"])
                          ], 8, _hoisted_11))
                        }), 128))
                      ], 42, _hoisted_9)), [
                        [_vModelSelect, _unref(value)]
                      ])
                    : (props.type == 'textarea')
                      ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                          key: 2,
                          name: props.inputName,
                          class: _normalizeClass(`${getInputClass.value} ${isValidClassComputed.value} m-0`),
                          id: props.inputName,
                          placeholder: getPlaceholder(),
                          "aria-describedby": `${props.inputName}GroupPrepend ${props.inputName}Feedback`,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                          disabled: __props.disabled,
                          onKeyup: _withKeys(emitValue, ["enter"]),
                          onChange: _cache[3] || (_cache[3] = 
                    () => {
                      if (props.type === 'date') {
                        emitValue();
                      }
                    }
                  )
                        }, null, 42, _hoisted_12)), [
                          [_vModelText, _unref(value)]
                        ])
                      : _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 3,
                          type: props.type,
                          name: props.inputName,
                          class: _normalizeClass(`${getInputClass.value} ${isValidClassComputed.value} m-0`),
                          id: props.inputName,
                          placeholder: getPlaceholder(),
                          "aria-describedby": `${props.inputName}GroupPrepend ${props.inputName}Feedback`,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                          disabled: __props.disabled,
                          onKeyup: _withKeys(emitValue, ["enter"]),
                          onChange: _cache[5] || (_cache[5] = 
                    () => {
                      if (props.type === 'date') {
                        emitValue();
                      }
                    }
                  )
                        }, null, 42, _hoisted_13)), [
                          [_vModelDynamic, _unref(value)]
                        ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formButtons.value.endAppendButtons, (endButton, endButtonIndex) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `endAppendButton-${props.inputName}-${endButtonIndex}`
                  }, [
                    (
                      endButton.conditions ? endButton.conditions(_unref(value)) : true
                    )
                      ? (_openBlock(), _createBlock(ButtonField, {
                          key: 0,
                          "button-field": endButton
                        }, null, 8, ["button-field"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ], 32)
            ], 8, _hoisted_8),
            _createElementVNode("ul", {
              ref: props.inputName,
              class: "dropdown-menu",
              id: `${props.inputName}DropdownMenu`
            }, [
              (__props.errorBag && __props.errorBag[__props.path])
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.errorBag[__props.path], (error, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: `${error}+${index}+${props.label}`,
                        class: _normalizeClass(`mx-3 ${
                    error.valid ? 'text-success' : 'text-danger'
                  } text-nowrap`)
                      }, [
                        _createElementVNode("span", {
                          innerHTML: error.error
                        }, null, 8, _hoisted_16)
                      ], 2))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_14)
          ], 2),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formButtons.value.endButtons, (endButton, endButtonIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-auto",
              key: `endButton${props.inputName}-${endButtonIndex}`
            }, [
              (endButton.conditions ? endButton.conditions(_unref(value)) : true)
                ? (_openBlock(), _createBlock(ButtonField, {
                    key: 0,
                    "button-field": endButton
                  }, null, 8, ["button-field"]))
                : _createCommentVNode("", true)
            ]))
          }), 128)),
          (mergedMetadata.value?.labelPosition === 'right' && props.label)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("span", {
                  class: "fw-medium fs-6 pl-1",
                  id: `${props.inputName}GroupPrepend`
                }, [
                  _createVNode(ProcessText, {
                    value: props.label
                  }, null, 8, ["value"])
                ], 8, _hoisted_18)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})