import { t } from "@/shared/locales/services/i18n.services";
import { UserInterface } from "@/store/auth/interfaces/User.interface";
import { CatalogClassesKeys } from "@/store/catalogs/const/CatalogClasses.const";
import { CatalogClassInterface } from "@/store/catalogs/interfaces/CatalogClass.interface";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";
import { Profile } from "@/store/profiles/models/Profile.model";
import { Menu } from "../../../shared/globals/menu/types/Menu.type";
import { TableHeader } from "../../../shared/globals/tables/interfaces/TableHeader.interface";

export const UsersHeader = (
  optionEdit,
  optionDelete,
  optionDisable,
  optionEnable,
  customFields: CustomFieldInterface[],
  profiles: Profile[],
  catalogs: CatalogClassInterface[]
): TableHeader[] => {
  const customFieldsHeaders: TableHeader[] = customFields.map(
    (customField: CustomFieldInterface) => {
      return {
        sortable: false,
        value: { value: customField.name, needsTranslate: false },
        key: customField.id,
        mappedKey: customField.id,
        columnType: customField.type,
        width: "380px",
        ...(customField?.metadata
          ? { metadata: { customFieldMetadata: customField.metadata } }
          : {}),
        isCustomField: true,
      };
    }
  );
  const userStatusCatalog = catalogs.find(
    (catalog) => catalog.key === CatalogClassesKeys.USERS_STATUS_CLASS
  );
  return [
    {
      sortable: true,
      value: { value: "global.tables.users.id", needsTranslate: true },
      key: "id",
      mappedKey: "id",
      columnType: "number",
      width: "380px",
    },
    {
      sortable: true,
      value: { value: "global.tables.users.name", needsTranslate: true },
      key: "name",
      mappedKey: "name",
      columnType: "text",
      width: "100px",
    },
    {
      sortable: true,
      value: { value: "global.tables.users.email", needsTranslate: true },
      key: "email",
      mappedKey: "email",
      columnType: "text",
      width: "150px",
    },
    {
      sortable: true,
      value: { value: "global.tables.users.profile", needsTranslate: true },
      key: "profile",
      mappedKey: "profile.id",
      columnType: "profile",
      width: "150px",
      metadata: {
        profiles,
        filterMetadata: {
          options: [
            {
              value: null,
              name: {
                value: "global.tables.selectNull",
                needsTranslate: true,
                params: { entity: t("global.tables.users.profile") },
              },
            },
            ...profiles.map((profile) => {
              return {
                value: profile.id,
                name: { value: profile.name, needsTranslate: false },
              };
            }),
          ],
        },
      },
    },
    {
      sortable: true,
      value: { value: "global.tables.users.createdAt", needsTranslate: true },
      key: "createdAt",
      mappedKey: "createdAt",
      columnType: "date",
      width: "150px",
    },
    {
      sortable: true,
      value: { value: "global.tables.users.updatedAt", needsTranslate: true },
      key: "updatedAt",
      mappedKey: "updatedAt",
      columnType: "date",
      width: "190px",
    },
    {
      sortable: true,
      value: { value: "global.tables.users.status", needsTranslate: true },
      key: "status",
      mappedKey: "status.id",
      columnType: "catalog",
      width: "120px",
      metadata: {
        catalog: userStatusCatalog,
        filterMetadata: {
          options: [
            {
              value: null,
              name: {
                value: "global.tables.selectNull",
                needsTranslate: true,
                params: { entity: t("global.tables.users.status") },
              },
            },
            ...userStatusCatalog.catalogs.map((status) => {
              return {
                value: status.id,
                name: { value: status.name, needsTranslate: false },
              };
            }),
          ],
        },
      },
    },
    ...customFieldsHeaders,
    {
      sortable: true,
      value: { value: "global.tables.users.actions", needsTranslate: true },
      columnType: "action",
      options: (user: UserInterface, index: number): Menu => {
        const catalog = userStatusCatalog.catalogs.find(
          (catalog) => catalog.id === user.status.id
        );
        let options = [
          {
            label: {
              value: "global.tables.users.actionList.edit",
              needsTranslate: true,
            },
            id: 1,
            action: () => optionEdit(user, index),
          },
          {
            label: {
              value: "global.tables.users.actionList.delete",
              needsTranslate: true,
            },
            id: 2,
            action: () => optionDelete(user, index),
          },
        ];
        if (catalog.name === "active") {
          options = [
            ...options,
            {
              label: {
                value: "global.tables.users.actionList.disable",
                needsTranslate: true,
              },
              id: 3,
              action: () => optionDisable(user, index),
            },
          ];
        } else {
          options = [
            ...options,
            {
              label: {
                value: "global.tables.users.actionList.enable",
                needsTranslate: true,
              },
              id: 4,
              action: () => optionEnable(user, index),
            },
          ];
        }
        return {
          options,
        };
      },
      width: "95px",
    },
  ];
};
