import { buttonTranslate } from "@/components/customFields/forms/templates/CreateOrUpdateSelectSettings.template";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  minCharsYup,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { Permission } from "@/store/permissions/models/Permission.model";

export const CreateOrUpdateProfileTemplate = (
  action: () => void,
  isCreating: boolean,
  actionTranslate: (fieldName) => void,
  permissions: Permission[]
): Form => {
  const { lang } = useLanguageServices();

  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "profile.name",
            inputName: "profileName",
            label: {
              value: "profiles.form.name",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, minCharsYup(5)];
            },
            buttons: [
              { ...buttonTranslate, action: () => actionTranslate("name") },
            ],
          },
          {
            path: "profile.description",
            inputName: "profileDescription",
            label: {
              value: "profiles.form.description",
              needsTranslate: true,
            },
            type: "textarea",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
            buttons: [
              {
                ...buttonTranslate,
                action: () => actionTranslate("description"),
              },
            ],
          },
          {
            path: "profile.permissions",
            inputName: "profilePermissions",
            label: {
              value: "profiles.form.permissions",
              needsTranslate: true,
            },
            type: "multiple-select",
            metadata: {
              tooltipPosition: "top",
            },
            options: permissions.map((permission: Permission) => {
              return {
                name: {
                  value:
                    permission?.translates?.name?.[lang.value] ??
                    permission.name,
                  needsTranslate: false,
                },
                value: permission.id,
              };
            }),
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating ? "profiles.form.add" : "profiles.form.edit",
        needsTranslate: true,
      },
      action,
    },
  };
  return form;
};
