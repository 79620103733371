import { createStore } from "vuex";
import { authStore } from "./auth/auth";
import { modulesStore } from "./modules/modules";
import { permissionsStore } from "./permissions/permissions";
import { profilesStore } from "./profiles/profiles";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth: authStore,
    modules: modulesStore,
    profiles: profilesStore,
    permissions: permissionsStore,
  },
});
