import { ADMIN_PROFILE_ID } from "@/store/profiles/consts/admin-profile-id.const";
import { RouterActionMenu } from "../types/ActionsSidebarElements.type";
import {
  SidebarElement,
  SidebarElementSubmenu,
} from "../types/SidebarElements.type";

function permissionAllowed(user, routeName) {
  const { profile } = user;
  if (profile.id === ADMIN_PROFILE_ID) {
    return true;
  }
  return user?.profile?.permissions?.some((permission) => {
    return permission.name === routeName;
  });
}

function createSideBarElement(
  user,
  element: SidebarElement | SidebarElementSubmenu
): SidebarElement[] | SidebarElementSubmenu[] {
  const tempElement = element;
  if (tempElement.submenu) {
    tempElement.submenu = element.submenu.filter(
      (menu: SidebarElementSubmenu) => {
        const [m] = createSideBarElement(user, menu);
        return m;
      }
    );
  }
  if ("name" in tempElement) {
    if (element.submenu) {
      if (tempElement.submenu.length === 0) {
        return [];
      }
      return [tempElement];
    } else {
      if (
        permissionAllowed(user, (tempElement.action as RouterActionMenu).route)
      ) {
        return [tempElement];
      }
      return [];
    }
  } else {
    if (
      permissionAllowed(user, (tempElement.action as RouterActionMenu).route)
    ) {
      return [tempElement];
    }
    return [];
  }
}

export const SidebarElements = (user): SidebarElement[] => {
  const headers = [
    {
      name: "Home",
      icon: null,
      type: "element",
      i18nName: "home.navbar.elements.home",
      action: { route: "Dashboard", metadata: {} },
    },
    {
      name: "UsersManagement",
      icon: null,
      type: "element",
      i18nName: "home.navbar.elements.usersManagment.name",
      action: null,
      submenu: [
        {
          i18nName: "home.navbar.elements.usersManagment.dropdowns.users",
          action: { route: "Users", metadata: {} },
        },
        {
          i18nName: "home.navbar.elements.usersManagment.dropdowns.profiles",
          action: { route: "Profiles", metadata: {} },
        },
      ],
    },
    {
      name: "Crm",
      icon: null,
      type: "element",
      i18nName: "home.navbar.elements.crm.name",
      action: null,
      submenu: [
        {
          i18nName: "home.navbar.elements.crm.dropdowns.customers",
          action: { route: "Customers", metadata: {} },
        },
        {
          i18nName: "home.navbar.elements.crm.dropdowns.providers",
          action: { route: "Providers", metadata: {} },
        },
      ],
    },
    {
      name: "ProductsManagment",
      icon: null,
      type: "element",
      i18nName: "home.navbar.elements.productsManagment.name",
      action: null,
      submenu: [
        {
          i18nName: "home.navbar.elements.productsManagment.dropdowns.products",
          action: { route: "Products", metadata: {} },
        },
        {
          i18nName:
            "home.navbar.elements.productsManagment.dropdowns.rawMaterials",
          action: { route: "RawMaterials", metadata: {} },
        },
        {
          i18nName:
            "home.navbar.elements.productsManagment.dropdowns.productFamily",
          action: { route: "ProductFamily", metadata: {} },
        },
        {
          i18nName: "home.navbar.elements.productsManagment.dropdowns.measures",
          action: { route: "Measures", metadata: {} },
        },
      ],
    },
    {
      name: "Operations",
      icon: null,
      type: "element",
      i18nName: "home.navbar.elements.operations.name",
      action: null,
      submenu: [
        {
          i18nName: "home.navbar.elements.operations.dropdowns.orders",
          action: { route: "Orders", metadata: {} },
        },
        {
          i18nName: "home.navbar.elements.operations.dropdowns.shipping",
          action: { route: "Shipping", metadata: {} },
        },
        {
          i18nName: "home.navbar.elements.operations.dropdowns.inventories",
          action: { route: "Inventories", metadata: {} },
        },
      ],
    },
    {
      name: "Workplace",
      icon: null,
      type: "element",
      i18nName: "home.navbar.elements.workplace",
      action: { route: "Workplace", metadata: {} },
    },
    {
      name: "Reports",
      icon: null,
      type: "element",
      i18nName: "home.navbar.elements.reports",
      action: { route: "Reports", metadata: {} },
    },
    {
      name: "Setting",
      icon: null,
      type: "element",
      i18nName: "home.navbar.elements.settings",
      action: { route: "Setting", metadata: {} },
    },
  ]
    .map((element) => {
      const e = createSideBarElement(user, element) as SidebarElement[];
      return e?.at(0);
    })
    .filter((element) => element);
  return headers;
};
